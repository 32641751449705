import { parse, stringify } from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router';

function PrivateRoute({ authenticated, component: Component, id, ...other }) {
    const { redirectRoute, ...rest } = parse(other.location.search);
    const loginPath = [
        '/login',
        other.location.pathname === '/' ? '' : `?redirectRoute=${other.location.pathname}`,
        Object.entries(rest).length === 0 ? '' : `?${stringify(rest)}`,
    ].join('');
    return (
        <Route
            {...other}
            render={() => (authenticated ? <Component id={id} /> : <Redirect to={loginPath} />)}
        />
    );
}

function mapStateToProps({ authenticated }) {
    return { authenticated };
}

export default connect(mapStateToProps)(PrivateRoute);
