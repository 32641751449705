/* eslint-disable import/prefer-default-export */

import externalIdParamsFunction from '../../App/CompanyExternalId/externalIdParamsFunction';
import { addItem, fetchCollection, fetchItem, updateItem } from '../collectionActions';

const fetchApms = fetchCollection({
    apiCollectionName: 'apms',
    collectionActionName: 'APMS',
});

const fetchApmsOverClients = fetchCollection({
    apiCollectionName: 'apms',
    collectionActionName: 'APMS',
    useClientPrefix: false,
});

const addApm = addItem({
    apiCollectionName: 'apms',
    itemActionName: 'APM',
    paramsFunction: externalIdParamsFunction,
});

const fetchApm = fetchItem({
    apiCollectionName: 'apms',
    apiVersion: 'v3',
    itemActionName: 'APM',
});

const updateApm = updateItem({
    apiCollectionName: 'apms',
    apiVersion: 'v2',
    itemActionName: 'APM',
    paramsFunction: externalIdParamsFunction,
});

export { addApm, fetchApm, fetchApms, fetchApmsOverClients, updateApm };
