/* eslint-disable import/prefer-default-export */

import apiRequestActionCreator from './apiRequestActionCreator';

export function fetchLayout({ apmId, clientId }) {
    return apiRequestActionCreator({
        actionName: 'LAYOUT',
        blockParallel: true,
        params: {
            url: `/stateful/v1/clients/${clientId}/apms/${apmId}/layout`,
        },
    });
}
