/* eslint-disable import/prefer-default-export */

import apiRequestActionCreator from './apiRequestActionCreator';

export function fetchApmSlots({ clientId, apmId }) {
    return apiRequestActionCreator({
        actionName: 'APM_SLOTS',
        params: {
            url: `/stateful/v1/clients/${clientId}/apms/${apmId}/slots`,
        },
    });
}

export function resetApmSlots() {
    return { type: 'APM_SLOTS_RESET' };
}
