import { LoadingPage } from 'cleveron-web-components';
import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import ConnectedAppWrapper from '../ConnectedAppWrapper';
import ConnectedAuthWrapper from '../ConnectedAuthWrapper';
import PrivateRoute from '../PrivateRoute';
import Error404 from '../statuses/Error404';
import usePermissions from '../usePermissions';
import PasswordResetToken from './Auth/PasswordResetToken';
import RegistrationToken from './Auth/RegistrationToken';

const Administrator = lazy(() => import('./Administrator'));
const AdministratorRole = lazy(() => import('./AdministratorRole'));
const AdministratorRoles = lazy(() => import('./AdministratorRoles'));
const Administrators = lazy(() => import('./Administrators'));
const Apm = lazy(() => import('./Apm'));
const ApmGroup = lazy(() => import('./ApmGroup'));
const ApmGroups = lazy(() => import('./ApmGroups'));
const ApmLanguage = lazy(() => import('./ApmLanguage'));
const ApmLanguagePack = lazy(() => import('./ApmLanguagePack'));
const ApmLanguagePacks = lazy(() => import('./ApmLanguagePacks'));
const ApmLanguages = lazy(() => import('./ApmLanguages'));
const ApmLocation = lazy(() => import('./ApmLocation'));
const ApmLocations = lazy(() => import('./ApmLocations'));
const ApmReminderSchedule = lazy(() => import('./ApmReminderSchedule'));
const ApmReminderSchedules = lazy(() => import('./ApmReminderSchedules'));
const ApmRole = lazy(() => import('./ApmRole'));
const ApmRoles = lazy(() => import('./ApmRoles'));
const Apms = lazy(() => import('./Apms'));
const Mailrooms = lazy(() => import('./Mailrooms'));
const Mailroom = lazy(() => import('./Mailroom'));
const ApmServiceHoursItem = lazy(() => import('./ApmServiceHoursItem'));
const ApmServiceHoursList = lazy(() => import('./ApmServiceHoursList'));
const ApmTheme = lazy(() => import('./ApmTheme'));
const ApmThemes = lazy(() => import('./ApmThemes'));
const Client = lazy(() => import('./Client'));
const ClientGroup = lazy(() => import('./ClientGroup'));
const ClientGroups = lazy(() => import('./ClientGroups'));
const ClientsAdministrator = lazy(() => import('./Clients/Administrator'));
const ClientsSuperuser = lazy(() => import('./Clients/Superuser'));
const Companies = lazy(() => import('./Companies'));
const Company = lazy(() => import('./Company'));
const GlobalOrders = lazy(() => import('./GlobalOrders'));
const Login = lazy(() => import('./Login'));
const Registration = lazy(() => import('./Auth/Registration'));
const PasswordReset = lazy(() => import('./Auth/PasswordReset'));
const Logout = lazy(() => import('./Logout'));
const OfficeRole = lazy(() => import('./OfficeRole'));
const OfficeRoles = lazy(() => import('./OfficeRoles'));
const Order = lazy(() => import('./Order'));
const OrderEdit = lazy(() => import('./Order/OrderEdit'));
const OrderNew = lazy(() => import('./Order/OrderNew'));
const Orders = lazy(() => import('./Orders'));
const OrdersImport = lazy(() => import('./OrdersImport'));
const PrivacyPolicies = lazy(() => import('./PrivacyPolicies'));
const PrivacyPolicy = lazy(() => import('./PrivacyPolicy'));
const Root = lazy(() => import('./Root'));
const SlotGroup = lazy(() => import('./SlotGroup'));
const SlotGroups = lazy(() => import('./SlotGroups'));
const Statistics = lazy(() => import('./Statistics'));
const Template = lazy(() => import('./Template'));
const Templates = lazy(() => import('./Templates'));
const User = lazy(() => import('./User'));
const UserAcceptPrivacyPolicy = lazy(() => import('./User/AcceptPrivacyPolicy'));
const UserChangePassword = lazy(() => import('./User/ChangePassword'));
const Users = lazy(() => import('./Users'));
const UsersImport = lazy(() => import('./UsersImport'));

const Routes = React.memo(({ id }) => {
    return (
        <Switch>
            <Route path={['/login', '/register', '/password/reset']}>
                <AuthRoutes id={`${id}Auth`} />
            </Route>
            <Route>
                <AppRoutes id={`${id}App`} />
            </Route>
        </Switch>
    );
});

const AuthRoutes = React.memo(({ id }) => {
    return (
        <ConnectedAuthWrapper id={id}>
            <Suspense fallback={<LoadingPage id={`${id}Loading`} />}>
                <Switch>
                    <Route exact path="/login" render={() => <Login id={`${id}Login`} />} />
                    <Route
                        exact
                        path="/register"
                        render={() => <Registration id={`${id}Registration`} />}
                    />
                    <Route
                        exact
                        path="/register/token"
                        render={() => <RegistrationToken id={`${id}RegistrationToken`} />}
                    />
                    <Route
                        exact
                        path="/password/reset"
                        render={() => <PasswordReset id={`${id}PasswordReset`} />}
                    />
                    <Route
                        exact
                        path="/password/reset/token"
                        render={() => <PasswordResetToken id={`${id}PasswordResetToken`} />}
                    />
                    <Route exact render={() => <Error404 id={`${id}Error404`} />} />
                </Switch>
            </Suspense>
        </ConnectedAuthWrapper>
    );
});

const AppRoutes = React.memo(({ id }) => {
    const { isAdministrator, isSuperuser } = usePermissions();
    return (
        <ConnectedAppWrapper id={id}>
            <Suspense fallback={<LoadingPage id={`${id}Loading`} />}>
                <Switch>
                    <Route exact path="/logout" render={() => <Logout id={`${id}Logout`} />} />
                    <PrivateRoute
                        component={Administrator}
                        id={`${id}Administrator`}
                        path="/administrators/:id"
                    />
                    <PrivateRoute
                        component={Administrators}
                        id={`${id}Administrators`}
                        path="/administrators"
                    />
                    <PrivateRoute
                        component={AdministratorRole}
                        id={`${id}AdministratorRole`}
                        path="/administratorRoles/:id"
                    />
                    <PrivateRoute
                        component={AdministratorRoles}
                        id={`${id}AdministratorRoles`}
                        path="/administratorRoles"
                    />
                    <PrivateRoute
                        component={Mailroom}
                        exact
                        id={`${id}Mailroom`}
                        path={[
                            '/clients/:clientId/mailrooms/:apmId',
                            '/clients/:clientId/mailrooms/:apmId/:viewId',
                        ]}
                    />
                    <PrivateRoute
                        component={Apm}
                        id={`${id}Apm`}
                        path="/clients/:clientId/apms/:id"
                    />
                    <PrivateRoute
                        component={Apms}
                        id={`${id}Apms`}
                        path="/clients/:clientId/apms"
                    />
                    <PrivateRoute
                        component={OrdersImport}
                        exact
                        id={`${id}OrdersImport`}
                        path="/clients/:clientId/orders/import"
                    />
                    <PrivateRoute
                        component={OrderEdit}
                        id={`${id}OrderEdit`}
                        path="/clients/:clientId/orders/:id/edit"
                    />
                    <PrivateRoute
                        component={OrderNew}
                        id={`${id}Order`}
                        path="/clients/:clientId/orders/new"
                    />
                    <PrivateRoute
                        component={Order}
                        id={`${id}Order`}
                        path="/clients/:clientId/orders/:id"
                    />
                    <PrivateRoute
                        component={Orders}
                        id={`${id}Orders`}
                        path="/clients/:clientId/orders"
                    />
                    <PrivateRoute component={GlobalOrders} id={`${id}Orders`} path="/all_orders" />
                    <PrivateRoute
                        component={UsersImport}
                        exact
                        id={`${id}UsersImport`}
                        path="/clients/:clientId/users/import"
                    />
                    <PrivateRoute
                        component={UserChangePassword}
                        exact
                        id={`${id}ChangePassword`}
                        path="/password/change"
                    />
                    <PrivateRoute
                        component={UserAcceptPrivacyPolicy}
                        exact
                        id={`${id}AcceptPrivacyPolicy`}
                        path="/acceptPrivacyPolicy"
                    />
                    <PrivateRoute
                        component={User}
                        id={`${id}User`}
                        path="/clients/:clientId/users/:id"
                    />
                    <PrivateRoute
                        component={Users}
                        id={`${id}Users`}
                        path="/clients/:clientId/users"
                    />
                    <PrivateRoute
                        component={Company}
                        id={`${id}Company`}
                        path="/clients/:clientId/companies/:id"
                    />
                    <PrivateRoute
                        component={Companies}
                        id={`${id}Companies`}
                        path="/clients/:clientId/companies"
                    />
                    <PrivateRoute
                        component={Mailrooms}
                        id={`${id}Mailrooms`}
                        path="/clients/:clientId/mailrooms"
                    />
                    <PrivateRoute
                        component={SlotGroup}
                        id={`${id}SlotGroup`}
                        path="/clients/:clientId/slotGroups/:id"
                    />
                    <PrivateRoute
                        component={SlotGroups}
                        id={`${id}SlotGroups`}
                        path="/clients/:clientId/slotGroups"
                    />
                    <PrivateRoute
                        component={Statistics}
                        id={`${id}GlobalStatistics`}
                        path="/statistics/:viewId"
                    />
                    <PrivateRoute
                        component={Statistics}
                        id={`${id}Statistics`}
                        path="/clients/:clientId/statistics/:viewId"
                    />
                    <PrivateRoute
                        component={Template}
                        id={`${id}Template`}
                        path="/clients/:clientId/templates/:id"
                    />
                    <PrivateRoute
                        component={Templates}
                        id={`${id}Templates`}
                        path="/clients/:clientId/templates"
                    />
                    <PrivateRoute
                        component={ApmReminderSchedule}
                        id={`${id}ApmNotification`}
                        path="/clients/:clientId/reminderschedules/:id"
                    />
                    <PrivateRoute
                        component={ApmReminderSchedules}
                        id={`${id}ApmNotifications`}
                        path="/clients/:clientId/reminderschedules"
                    />
                    <PrivateRoute
                        component={ApmGroup}
                        id={`${id}ApmGroup`}
                        path="/clients/:clientId/apm_groups/:id"
                    />
                    <PrivateRoute
                        component={ApmGroups}
                        id={`${id}ApmGroups`}
                        path="/clients/:clientId/apm_groups"
                    />
                    <PrivateRoute
                        component={ApmLocation}
                        id={`${id}ApmLocation`}
                        path="/clients/:clientId/apm_locations/:id"
                    />
                    <PrivateRoute
                        component={ApmLocations}
                        id={`${id}ApmLocations`}
                        path="/clients/:clientId/apm_locations"
                    />
                    <PrivateRoute
                        component={ApmServiceHoursItem}
                        id={`${id}ApmServiceHoursItem`}
                        path="/clients/:clientId/apm_service_hours/:id"
                    />
                    <PrivateRoute
                        component={ApmServiceHoursList}
                        id={`${id}ApmServiceHoursList`}
                        path="/clients/:clientId/apm_service_hours"
                    />
                    <PrivateRoute
                        component={OfficeRole}
                        id={`${id}OfficeRole`}
                        path="/clients/:clientId/office_roles/:id"
                    />
                    <PrivateRoute
                        component={OfficeRoles}
                        id={`${id}OfficeRoles`}
                        path="/clients/:clientId/office_roles"
                    />
                    <PrivateRoute
                        component={ApmRole}
                        id={`${id}ApmRole`}
                        path="/clients/:clientId/apm_roles/:id"
                    />
                    <PrivateRoute
                        component={ApmRoles}
                        id={`${id}ApmRoles`}
                        path="/clients/:clientId/apm_roles"
                    />
                    <PrivateRoute
                        component={ApmTheme}
                        id={`${id}ApmTheme`}
                        path="/clients/:clientId/apm_themes/:id"
                    />
                    <PrivateRoute
                        component={ApmThemes}
                        id={`${id}ApmThemes`}
                        path="/clients/:clientId/apm_themes"
                    />
                    <PrivateRoute
                        component={ApmLanguagePack}
                        id={`${id}ApmLanguagePack`}
                        path="/clients/:clientId/apm_language_packs/:id"
                    />
                    <PrivateRoute
                        component={ApmLanguagePacks}
                        id={`${id}ApmLanguagePacks`}
                        path="/clients/:clientId/apm_language_packs"
                    />
                    <PrivateRoute
                        component={ApmLanguage}
                        id={`${id}ApmLanguage`}
                        path="/clients/:clientId/apm_languages/:id"
                    />
                    <PrivateRoute
                        component={ApmLanguages}
                        id={`${id}ApmLanguages`}
                        path="/clients/:clientId/apm_languages"
                    />
                    <PrivateRoute
                        component={Client}
                        exact
                        id={`${id}ClientSettings`}
                        path={['/clients/:id', '/clients/:id/:viewId']}
                    />
                    {isAdministrator && (
                        <PrivateRoute
                            component={ClientsAdministrator}
                            id={`${id}Clients`}
                            path="/clients"
                        />
                    )}
                    {isSuperuser && (
                        <PrivateRoute
                            component={ClientsSuperuser}
                            id={`${id}Clients`}
                            path="/clients"
                        />
                    )}
                    {isSuperuser && (
                        <PrivateRoute
                            component={PrivacyPolicy}
                            exact
                            id={`${id}PrivacyPolicy`}
                            path="/privacyPolicy/:id"
                        />
                    )}
                    {isSuperuser && (
                        <PrivateRoute
                            component={PrivacyPolicies}
                            exact
                            id={`${id}PrivacyPolicies`}
                            path="/privacyPolicy"
                        />
                    )}
                    <PrivateRoute
                        component={ClientGroup}
                        id={`${id}ClientGroup`}
                        path="/clientGroups/:id"
                    />
                    <PrivateRoute
                        component={ClientGroups}
                        id={`${id}ClientGroups`}
                        path="/clientGroups"
                    />
                    <PrivateRoute component={Root} exact id={`${id}Root`} path="/" />
                    <Route exact render={() => <Error404 id={`${id}Error404`} />} />
                </Switch>
            </Suspense>
        </ConnectedAppWrapper>
    );
});

export default Routes;
