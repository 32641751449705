/* eslint-disable import/prefer-default-export */
import {
    AccountBalance as AccountBalanceIcon,
    AccountTree as AccountTreeIcon,
    Alarm as AlarmIcon,
    AllInbox as AllInboxIcon,
    Apartment as ApartmentIcon,
    AssignmentTurnedIn as AssignmentTurnedInIcon,
    BusinessCenter as BusinessCenterIcon,
    CreateNewFolder as CreateNewFolderIcon,
    Dashboard as DashboardIcon,
    Domain as DomainIcon,
    Equalizer as EqualizerIcon,
    Group as GroupIcon,
    GroupWork as GroupWorkIcon,
    Language as LanguageIcon,
    MarkunreadMailbox as MarkunreadMailboxIcon,
    Palette as PaletteIcon,
    Place as PlaceIcon,
    Policy as PolicyIcon,
    Schedule as ScheduleIcon,
    Timeline as TimelineIcon,
    ViewModule as ViewModuleIcon,
    VpnKey as VpnKeyIcon,
    Work as WorkIcon,
} from '@mui/icons-material';
import React from 'react';
import { defineMessage, FormattedMessage } from 'react-intl';

import countries from './countries.json';
import tz from './timezones.json';

export const COUNTRIES = countries;

export const TIMEZONE_OPTIONS = tz.map(label => ({ label, value: label }));

export const PERMISSIONS = {
    APM_GROUPS_ADMIN: 26,
    APM_LANGUAGES_ADMIN: 15,
    APM_LOCATIONS_ADMIN: 27,
    APM_THEMES_ADMIN: 13,
    APMS_ADMIN: 8,
    APMS_REMOTE: 10,
    APMS_VIEW: 9,
    CLIENTS: 0,
    COMPANIES_ADMIN: 25,
    CONFIGURATIONS: 17,
    COURIER_APP_DOWNLOAD: 23,
    DOC_VIEW: 11,
    MAILROOM_ADMIN: 35,
    NOTIFICATIONS_ADMIN: 32,
    NOTIFICATIONS_SEND: 33,
    ORDERS_ADMIN: 12,
    ORDERS_VIEW: 6,
    ROLES_ADMIN: 24,
    SLOT_GROUPS: 34,
    SLOT_RESERVATION: 16,
    STATS_VIEW: 20,
    TEMPLATES_ADMIN: 22,
    USERS_ADMIN: 7,
};

export const APM_PERMISSIONS = {
    INVENTORY: 4,
    OPERIN: 1,
    OPEROUT: 2,
    OPEROUT_SUPER: 3,
    SERVICE_INTERFACE: 5,
    SERVICE_INTERFACE_LTD: 19,
    SIMPLE_MODE: 36,
};

export const OFFICE_PERMISSIONS_STRUCTURE = [
    {
        editPermission: PERMISSIONS.ORDERS_ADMIN,
        editPermissionDescriptionTranslation: defineMessage({
            defaultMessage: 'The right to edit and create orders',
            id: 'permissions.office.orders.edit.description',
        }),
        translation: defineMessage({ defaultMessage: 'Orders', id: 'permissions.office.orders' }),
        viewPermission: PERMISSIONS.ORDERS_VIEW,
        viewPermissionDescriptionTranslation: defineMessage({
            defaultMessage: 'The right to search, see and export order data',
            id: 'permissions.office.orders.view.description',
        }),
    },
    {
        editPermission: PERMISSIONS.APMS_ADMIN,
        editPermissionDescriptionTranslation: defineMessage({
            defaultMessage: 'The right to view and administrate APMs',
            id: 'permissions.office.apms.edit.description',
        }),
        translation: defineMessage({ defaultMessage: 'APMs', id: 'permissions.office.apms' }),
        viewPermission: PERMISSIONS.APMS_VIEW,
        viewPermissionDescriptionTranslation: defineMessage({
            defaultMessage: 'The right to view APM data',
            id: 'permissions.office.apms.view.description',
        }),
    },
    {
        translation: defineMessage({
            defaultMessage: 'APM remote control',
            id: 'permissions.office.apmRemoteControl',
        }),
        viewPermission: PERMISSIONS.APMS_REMOTE,
        viewPermissionDescriptionTranslation: defineMessage({
            defaultMessage:
                'The right to remotely open the slot doors and change the status of the slots',
            id: 'permissions.office.apmRemoteControl.action.description',
        }),
    },
    {
        editPermission: PERMISSIONS.SLOT_RESERVATION,
        editPermissionDescriptionTranslation: defineMessage({
            defaultMessage: 'Permission to reserve slots for companies',
            id: 'permissions.office.apmSlotReservation.edit.description',
        }),
        translation: defineMessage({
            defaultMessage: 'APM slot reservation',
            id: 'permissions.office.apmSlotReservation',
        }),
    },
    {
        editPermission: PERMISSIONS.SLOT_GROUPS,
        editPermissionDescriptionTranslation: defineMessage({
            defaultMessage: 'The right to configure slot groups',
            id: 'permissions.office.apmSlotGroups.edit.description',
        }),
        translation: defineMessage({
            defaultMessage: 'APM slot groups',
            id: 'permissions.office.apmSlotGroups',
        }),
    },
    {
        editPermission: PERMISSIONS.APM_LOCATIONS_ADMIN,
        editPermissionDescriptionTranslation: defineMessage({
            defaultMessage: 'The right to view and administrate APM locations',
            id: 'permissions.office.apmLocations.edit.description',
        }),
        translation: defineMessage({
            defaultMessage: 'APM locations',
            id: 'permissions.office.apmLocations',
        }),
    },
    {
        editPermission: PERMISSIONS.APM_THEMES_ADMIN,
        editPermissionDescriptionTranslation: defineMessage({
            defaultMessage:
                'Permissions to view, create, edit APM UI themes and apply theme to APMs',
            id: 'permissions.office.apmTheme.edit.description',
        }),
        translation: defineMessage({
            defaultMessage: 'APM themes',
            id: 'permissions.office.apmThemes',
        }),
    },
    {
        editPermission: PERMISSIONS.APM_LANGUAGES_ADMIN,
        editPermissionDescriptionTranslation: defineMessage({
            defaultMessage: 'Permission to manage languages and publish them to APMs',
            id: 'permissions.office.apmLanguages.edit.description',
        }),
        translation: defineMessage({
            defaultMessage: 'APM languages',
            id: 'permissions.office.apmLanguages',
        }),
    },
    {
        editPermission: PERMISSIONS.USERS_ADMIN,
        editPermissionDescriptionTranslation: defineMessage({
            defaultMessage: 'The right to view and administrate users',
            id: 'permissions.office.users.edit.description',
        }),
        translation: defineMessage({ defaultMessage: 'Users', id: 'permissions.office.users' }),
    },
    {
        editPermission: PERMISSIONS.ROLES_ADMIN,
        editPermissionDescriptionTranslation: defineMessage({
            defaultMessage: 'The right to view and administrate APM and Cleveroffice roles',
            id: 'permissions.office.roles.edit.description',
        }),
        translation: defineMessage({ defaultMessage: 'Roles', id: 'permissions.office.roles' }),
    },
    {
        editPermission: PERMISSIONS.APM_GROUPS_ADMIN,
        editPermissionDescriptionTranslation: defineMessage({
            defaultMessage: 'The right to view and administrate APM groups',
            id: 'permissions.office.apmGroup.edit.description',
        }),
        translation: defineMessage({
            defaultMessage: 'APM groups',
            id: 'permissions.office.apmGroups',
        }),
    },
    {
        editPermission: PERMISSIONS.COMPANIES_ADMIN,
        editPermissionDescriptionTranslation: defineMessage({
            defaultMessage: 'The right to view and administrate companies',
            id: 'permissions.office.companies.edit.description',
        }),
        translation: defineMessage({
            defaultMessage: 'Companies',
            id: 'permissions.office.companies',
        }),
    },
    {
        editPermission: PERMISSIONS.TEMPLATES_ADMIN,
        editPermissionDescriptionTranslation: defineMessage({
            defaultMessage: 'Permission to create and configure templates',
            id: 'permissions.office.templates.edit.description',
        }),
        translation: defineMessage({
            defaultMessage: 'Templates',
            id: 'permissions.office.templates',
        }),
    },
    {
        editPermission: PERMISSIONS.NOTIFICATIONS_ADMIN,
        editPermissionDescriptionTranslation: defineMessage({
            defaultMessage: 'The right to configure recurrent notifications',
            id: 'permissions.office.notifications.edit.description',
        }),
        translation: defineMessage({
            defaultMessage: 'Notifications and reminders',
            id: 'permissions.office.notifications',
        }),
    },
    {
        editPermission: PERMISSIONS.NOTIFICATIONS_SEND,
        editPermissionDescriptionTranslation: defineMessage({
            defaultMessage: 'The right to manually send notifications',
            id: 'permissions.office.notificationsSend.action.description',
        }),
        translation: defineMessage({
            defaultMessage: 'Notifications send',
            id: 'permissions.office.notificationsSend',
        }),
    },
    {
        translation: defineMessage({
            defaultMessage: 'APM statistics',
            id: 'permissions.office.statistics',
        }),
        viewPermission: PERMISSIONS.STATS_VIEW,
        viewPermissionDescriptionTranslation: defineMessage({
            defaultMessage: 'The right to view APM event statistics',
            id: 'permissions.office.statistics.view.description',
        }),
    },
    {
        translation: defineMessage({
            defaultMessage: 'API documentation',
            id: 'permissions.office.apiDocumentation',
        }),
        viewPermission: PERMISSIONS.DOC_VIEW,
        viewPermissionDescriptionTranslation: defineMessage({
            defaultMessage: 'The right to view documentation page',
            id: 'permissions.office.apiDocumentation.view.description',
        }),
    },
    {
        translation: defineMessage({
            defaultMessage: 'Courier App',
            id: 'permissions.office.courierApp',
        }),
        viewPermission: PERMISSIONS.COURIER_APP_DOWNLOAD,
        viewPermissionDescriptionTranslation: defineMessage({
            defaultMessage: 'Permission to download Courier App',
            id: 'permissions.office.mailroom.view.description',
        }),
    },
    {
        editPermission: PERMISSIONS.MAILROOM_ADMIN,
        editPermissionDescriptionTranslation: defineMessage({
            defaultMessage: 'The right to configure mailroom',
            id: 'permissions.office.mailroom.edit.description',
        }),
        translation: defineMessage({
            defaultMessage: 'Mailroom',
            id: 'permissions.office.mailroom',
        }),
    },
    {
        editPermission: PERMISSIONS.CONFIGURATIONS,
        editPermissionDescriptionTranslation: defineMessage({
            defaultMessage: 'Permissions to edit and change APM and Office configurations',
            id: 'permissions.office.configurations.edit.description',
        }),
        translation: defineMessage({
            defaultMessage: 'Configurations',
            id: 'permissions.office.configurations',
        }),
    },
];

export const PERMISSION_TYPES = {
    APM: 1,
    OFFICE: 2,
};

export const WALMART_SLOT_TYPES = [
    'L_300_530_1190', // C
    'L_450_530_1190', // D
    'L_300_1090_1190', // B
];

export const DROPBOX_SLOT_TYPES = ['L_880_1350_1080'];

export const DROPOFF_TYPES = ['RETURN', 'SEND'];

export const TEMPERATURE_ZONES = ['LT', 'MT', 'HT'];

export const INTERVALS = {
    DAY: {
        id: 'DAY',
        translation: <FormattedMessage defaultMessage="Day" id="timeIntervals.day" />,
    },
    HOUR: {
        id: 'HOUR',
        translation: <FormattedMessage defaultMessage="Hour" id="timeIntervals.hour" />,
    },
    MONTH: {
        id: 'MONTH',
        translation: <FormattedMessage defaultMessage="Month" id="timeIntervals.month" />,
    },
};

export const TIME_PERIODS = {
    CURRENT_MONTH: {
        id: 'CURRENT_MONTH',
        translation: (
            <FormattedMessage defaultMessage="Current month" id="timePeriods.currentMonth" />
        ),
    },
    CURRENT_WEEK: {
        id: 'CURRENT_WEEK',
        translation: (
            <FormattedMessage defaultMessage="Current week" id="timePeriods.currentWeek" />
        ),
    },
    CURRENT_YEAR: {
        id: 'CURRENT_YEAR',
        translation: (
            <FormattedMessage defaultMessage="Current year" id="timePeriods.currentYear" />
        ),
    },
    CUSTOM: {
        id: 'CUSTOM',
        translation: <FormattedMessage defaultMessage="Custom" id="timePeriods.custom" />,
    },
    PREVIOUS_DAY: {
        id: 'PREVIOUS_DAY',
        translation: (
            <FormattedMessage defaultMessage="Previous day" id="timePeriods.previousDay" />
        ),
    },
    PREVIOUS_MONTH: {
        id: 'PREVIOUS_MONTH',
        translation: (
            <FormattedMessage defaultMessage="Previous month" id="timePeriods.previousMonth" />
        ),
    },
    PREVIOUS_WEEK: {
        id: 'PREVIOUS_WEEK',
        translation: (
            <FormattedMessage defaultMessage="Previous week" id="timePeriods.previousWeek" />
        ),
    },
    PREVIOUS_YEAR: {
        id: 'PREVIOUS_YEAR',
        translation: (
            <FormattedMessage defaultMessage="Previous year" id="timePeriods.previousYear" />
        ),
    },
    THREE_MONTHS: {
        id: 'THREE_MONTHS',
        translation: <FormattedMessage defaultMessage="3 months" id="timePeriods.threeMonths" />,
    },
    TODAY: {
        id: 'TODAY',
        translation: <FormattedMessage defaultMessage="Today" id="timePeriods.today" />,
    },
};

export const WEEKDAYS = {
    FRIDAY: 5,
    MONDAY: 1,
    SATURDAY: 6,
    SUNDAY: 7,
    THURSDAY: 4,
    TUESDAY: 2,
    WEDNESDAY: 3,
};

export const USER_TYPES = {
    ADMINISTRATOR: 16,
    PERSON: 15,
    SUPERUSER: 17,
};

export const ORDER_CONTEXTS = {
    LEFT_FOR_CUSTOMER_SERVICE: 22,
    MAILROOM_DROPOFF: 23,
    MAILROOM_RECEIVE: 24,
};

export const ADMINISTRATOR_PATHS = [
    {
        icon: BusinessCenterIcon,
        path: 'clients',
        translation: defineMessage({ defaultMessage: 'Clients', id: 'page.clients' }),
    },
    {
        icon: AllInboxIcon,
        path: 'all_orders',
        permissions: [PERMISSIONS.ORDERS_VIEW, PERMISSIONS.ORDERS_ADMIN],
        translation: defineMessage({ defaultMessage: 'All orders', id: 'page.all_orders' }),
    },
    {
        icon: EqualizerIcon,
        path: 'statistics/events',
        permissions: [PERMISSIONS.STATS_VIEW],
        textKey: 'page.all_statistics',
        translation: defineMessage({
            defaultMessage: 'All statistics',
            id: 'page.all_statistics',
        }),
    },
];

export const SUPERUSER_PATHS = [
    {
        icon: BusinessCenterIcon,
        path: 'clients',
        translation: defineMessage({ defaultMessage: 'Clients', id: 'page.clients' }),
    },
    {
        icon: AccountBalanceIcon,
        path: 'administrators',
        translation: defineMessage({
            defaultMessage: 'Administrators',
            id: 'page.administrators',
        }),
    },
    {
        icon: AccountTreeIcon,
        path: 'administratorRoles',
        translation: defineMessage({
            defaultMessage: 'Administrator roles',
            id: 'page.administratorRoles',
        }),
    },
    {
        icon: ApartmentIcon,
        path: 'clientGroups',
        translation: defineMessage({
            defaultMessage: 'Client groups',
            id: 'page.clientGroups',
        }),
    },
    {
        icon: PolicyIcon,
        path: 'privacyPolicy',
        translation: defineMessage({
            defaultMessage: 'Privacy policies',
            id: 'page.privacyPolicy',
        }),
    },
];

export const USER_PATHS = [
    [
        {
            icon: AssignmentTurnedInIcon,
            path: 'orders',
            permissions: [PERMISSIONS.ORDERS_VIEW, PERMISSIONS.ORDERS_ADMIN],
            translation: defineMessage({ defaultMessage: 'Orders', id: 'page.orders' }),
        },
        {
            icon: DashboardIcon,
            path: 'apms',
            permissions: [PERMISSIONS.APMS_ADMIN, PERMISSIONS.APMS_REMOTE, PERMISSIONS.APMS_VIEW],
            translation: defineMessage({ defaultMessage: 'APMs', id: 'page.apms' }),
        },
        {
            icon: MarkunreadMailboxIcon,
            path: 'mailrooms',
            permissions: [PERMISSIONS.MAILROOM_ADMIN],
            translation: defineMessage({
                defaultMessage: 'Mailroom',
                id: 'page.mailroom',
            }),
        },
        {
            icon: PlaceIcon,
            path: 'apm_locations',
            permissions: [PERMISSIONS.APM_LOCATIONS_ADMIN],
            translation: defineMessage({
                defaultMessage: 'APM locations',
                id: 'page.apm_locations',
            }),
        },
        {
            icon: ScheduleIcon,
            path: 'apm_service_hours',
            permissions: [PERMISSIONS.APMS_ADMIN],
            translation: defineMessage({
                defaultMessage: 'APM service hours',
                id: 'page.apm_service_hours',
            }),
        },
    ],
    [
        {
            icon: PaletteIcon,
            path: 'apm_themes',
            permissions: [PERMISSIONS.APM_THEMES_ADMIN],
            translation: defineMessage({
                defaultMessage: 'APM themes',
                id: 'page.apm_themes',
            }),
        },
        {
            icon: LanguageIcon,
            path: 'apm_language_packs',
            permissions: [PERMISSIONS.APM_LANGUAGES_ADMIN],
            translation: defineMessage({
                defaultMessage: 'APM language packs',
                id: 'page.apm_language_packs',
            }),
        },
        {
            icon: LanguageIcon,
            path: 'apm_languages',
            permissions: [PERMISSIONS.APM_LANGUAGES_ADMIN],
            translation: defineMessage({
                defaultMessage: 'APM languages',
                id: 'page.apm_languages',
            }),
        },
        {
            clientOfficeConfigPredicate: ({ showReminders }) => showReminders,
            icon: AlarmIcon,
            path: 'reminderschedules',
            permissions: [PERMISSIONS.NOTIFICATIONS_ADMIN],
            translation: defineMessage({
                defaultMessage: 'APM reminders',
                id: 'page.reminderschedules',
            }),
        },
    ],
    [
        {
            icon: GroupIcon,
            path: 'users',
            permissions: [PERMISSIONS.USERS_ADMIN],
            translation: defineMessage({ defaultMessage: 'Users', id: 'page.users' }),
        },
        {
            icon: WorkIcon,
            path: 'office_roles',
            permissions: [PERMISSIONS.ROLES_ADMIN],
            translation: defineMessage({
                defaultMessage: 'Office roles',
                id: 'page.office_roles',
            }),
        },
        {
            icon: ViewModuleIcon,
            path: 'apm_groups',
            permissions: [PERMISSIONS.APM_GROUPS_ADMIN],
            translation: defineMessage({
                defaultMessage: 'APM groups',
                id: 'page.apm_groups',
            }),
        },
        {
            icon: GroupWorkIcon,
            path: 'slotGroups',
            permissions: [PERMISSIONS.SLOT_GROUPS],
            translation: defineMessage({
                defaultMessage: 'APM slot groups',
                id: 'page.slotGroups',
            }),
        },
        {
            icon: VpnKeyIcon,
            path: 'apm_roles',
            permissions: [PERMISSIONS.ROLES_ADMIN],
            translation: defineMessage({ defaultMessage: 'APM roles', id: 'page.apm_roles' }),
        },
        {
            icon: DomainIcon,
            path: 'companies',
            permissions: [PERMISSIONS.COMPANIES_ADMIN],
            translation: defineMessage({ defaultMessage: 'Companies', id: 'page.companies' }),
        },
        {
            icon: CreateNewFolderIcon,
            path: 'templates',
            permissions: [PERMISSIONS.TEMPLATES_ADMIN],
            translation: defineMessage({ defaultMessage: 'Templates', id: 'page.templates' }),
        },
    ],
    [
        {
            icon: TimelineIcon,
            path: 'statistics/events',
            permissions: [PERMISSIONS.STATS_VIEW],
            translation: defineMessage({
                defaultMessage: 'Statistics',
                id: 'page.statistics',
            }),
        },
    ],
];

export const APM_SIDES = {
    BACK: '-1',
    FRONT: '1',
};

export const TEMPLATE_TYPES = {
    DELIVERY_EMAIL: 2,
    DELIVERY_SMS: 1,
    MAILROOM_DROPOFF_EMAIL: 5,
    MAILROOM_DROPOFF_SMS: 4,
    MAILROOM_NEW_RECEIVE_CODE_SMS: 3,
    PACKAGE_LABEL: 10,
    RETURN_EMAIL: 7,
    RETURN_SMS: 6,
    RETURNABLE_EMAIL: 9,
    RETURNABLE_SMS: 8,
};

export const SLOT_STATUSES = {
    DISABLED: 1,
    ENABLED: 0,
    OPERATOR_USE: 3,
    SPOILED: 4,
};

export const SLOT_STATUS_NAMES = {
    [SLOT_STATUSES.ENABLED]: <FormattedMessage defaultMessage="Enabled" id="slot.status.enabled" />,
    [SLOT_STATUSES.DISABLED]: (
        <FormattedMessage defaultMessage="Disabled" id="slot.status.disabled" />
    ),
    [SLOT_STATUSES.OPERATOR_USE]: (
        <FormattedMessage defaultMessage="Special usage" id="slot.status.specialUsage" />
    ),
    [SLOT_STATUSES.SPOILED]: <FormattedMessage defaultMessage="Spoiled" id="slot.status.spoiled" />,
};

export const SLOT_STATE_CHANGE_REASONS = {
    HARDWARE_FAILURE: 8,
    LEFT_OPEN_BY_COURIER: 9,
    LEFT_OPEN_ON_DROPOFF: 10,
    LEFT_OPEN_ON_RECEIVE: 2,
    SET_BY_CLEVERON: 5,
    SET_BY_COURIER: 3,
    SET_BY_END_USER: 4,
    SET_FROM_CLEVEROFFICE: 6,
    SET_FROM_MOBILE: 7,
    UNKNOWN: 1,
};

export const SLOT_STATE_CHANGE_REASONS_NAMES = {
    [SLOT_STATE_CHANGE_REASONS.HARDWARE_FAILURE]: (
        <FormattedMessage
            defaultMessage="Hardware failure"
            id="slot.slotStateChangeReasons.hardwareFailure"
        />
    ),
    [SLOT_STATE_CHANGE_REASONS.LEFT_OPEN_BY_COURIER]: (
        <FormattedMessage
            defaultMessage="Left open by courier"
            id="slot.slotStateChangeReasons.leftOpenByCourier"
        />
    ),
    [SLOT_STATE_CHANGE_REASONS.LEFT_OPEN_ON_DROPOFF]: (
        <FormattedMessage
            defaultMessage="Left open on dropoff"
            id="slot.slotStateChangeReasons.leftOpenOnDropoff"
        />
    ),
    [SLOT_STATE_CHANGE_REASONS.LEFT_OPEN_ON_RECEIVE]: (
        <FormattedMessage
            defaultMessage="Left open on receive"
            id="slot.slotStateChangeReasons.leftOpenOnReceive"
        />
    ),
    [SLOT_STATE_CHANGE_REASONS.SET_BY_CLEVERON]: (
        <FormattedMessage
            defaultMessage="Set by Cleveron"
            id="slot.slotStateChangeReasons.setByCleveron"
        />
    ),
    [SLOT_STATE_CHANGE_REASONS.SET_BY_COURIER]: (
        <FormattedMessage
            defaultMessage="Set by courier"
            id="slot.slotStateChangeReasons.setByCourier"
        />
    ),
    [SLOT_STATE_CHANGE_REASONS.SET_BY_END_USER]: (
        <FormattedMessage
            defaultMessage="Set by end user"
            id="slot.slotStateChangeReasons.setByEndUser"
        />
    ),
    [SLOT_STATE_CHANGE_REASONS.SET_FROM_CLEVEROFFICE]: (
        <FormattedMessage
            defaultMessage="Set from CleverOffice"
            id="slot.slotStateChangeReasons.setFromCleveroffice"
        />
    ),
    [SLOT_STATE_CHANGE_REASONS.SET_FROM_MOBILE]: (
        <FormattedMessage
            defaultMessage="Set from Mobile"
            id="slot.slotStateChangeReasons.setFromMobile"
        />
    ),
    [SLOT_STATE_CHANGE_REASONS.UNKNOWN]: (
        <FormattedMessage defaultMessage="Unknown" id="slot.slotStateChangeReasons.unknown" />
    ),
};

export const DEFAULT_CLIENT_SLOT_TYPES = {
    C_600_253_400: 'MAXINEST',
    L_300_1090_1190: 'B',
    L_300_530_1190: 'C',
    L_360_100_620: 'XS+',
    L_360_120_600: 'S',
    L_360_200_600: 'M',
    L_360_380_600: 'L',
    L_360_45_530: 'XS',
    L_360_600_600: 'XL',
    L_450_530_1190: 'D',
};

export const DEFAULT_CLIENT_OFFICE_PERMISSIONS = [
    PERMISSIONS.ORDERS_VIEW,
    PERMISSIONS.ORDERS_ADMIN,
    PERMISSIONS.APMS_VIEW,
    PERMISSIONS.APMS_ADMIN,
    PERMISSIONS.APMS_REMOTE,
    PERMISSIONS.SLOT_RESERVATION,
    PERMISSIONS.APM_LOCATIONS_ADMIN,
    PERMISSIONS.USERS_ADMIN,
    PERMISSIONS.ROLES_ADMIN,
    PERMISSIONS.APM_GROUPS_ADMIN,
    PERMISSIONS.COMPANIES_ADMIN,
    PERMISSIONS.TEMPLATES_ADMIN,
    PERMISSIONS.STATS_VIEW,
    PERMISSIONS.DOC_VIEW,
    PERMISSIONS.COURIER_APP_DOWNLOAD,
    PERMISSIONS.NOTIFICATIONS_SEND,
    PERMISSIONS.NOTIFICATIONS_ADMIN,
];

export const DEFAULT_CLIENT_APM_PERMISSIONS = [
    APM_PERMISSIONS.OPEROUT_SUPER,
    APM_PERMISSIONS.OPERIN,
    APM_PERMISSIONS.INVENTORY,
    APM_PERMISSIONS.SERVICE_INTERFACE,
    APM_PERMISSIONS.SERVICE_INTERFACE_LTD,
];

export const DEFAULT_CLIENT_PASSWORD_MIN_LENGTH = 7;

export const USER_ACTION_TOKEN_TYPES = {
    PASSWORD_RESET: 'PASSWORD_RESET',
    REGISTRATION: 'REGISTRATION',
};

export const API_KEY_TYPES = {
    INTERGRATION: 8,
    MOBILE: 13,
};
