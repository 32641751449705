/* eslint-disable import/prefer-default-export */
import { defineMessage } from 'react-intl';

import { SubmissionError } from '../form';
import apiRequestActionCreator from './apiRequestActionCreator';
import { getMessageForFieldError, processItemError } from './collectionActions';

export function processAuthenticationError(error) {
    if (error.response?.data?.extraData) {
        throw new SubmissionError({
            error: null,
            fields: error.response.data.extraData.reduce(
                (errors, err) => ({
                    ...errors,
                    [err.field]:
                        err.field === 'passwordPolicy'
                            ? err?.values?.policy
                            : getMessageForFieldError(err),
                }),
                {}
            ),
        });
    }

    throw new SubmissionError({
        error: defineMessage({
            defaultMessage:
                'Something unexpected happened. Please try again or contact administrator',
            id: 'error.auth.genericIssue',
        }),
    });
}

export function authenticate({ password, username }) {
    return apiRequestActionCreator({
        actionName: 'AUTHENTICATE',
        params: {
            data: { password, username },
            method: 'post',
            url: '/stateful/v1/authenticate',
        },
        processError: error => {
            const code = error.response?.data?.code;

            if (code === 1) {
                throw new SubmissionError({
                    error: defineMessage({
                        defaultMessage: 'Authentication failed. Please try again',
                        id: 'login.error.authenticationFailed',
                    }),
                });
            }

            return processAuthenticationError(error);
        },
    });
}

export function registerAndAuthenticate({ token, newPassword, acceptPrivacyPolicy }) {
    return apiRequestActionCreator({
        actionName: 'AUTHENTICATE',
        params: {
            data: { acceptPrivacyPolicy, newPassword, token },
            method: 'post',
            url: 'stateful/v1/auth/register',
        },
        processError: processAuthenticationError,
    });
}

export function resetPasswordAndAuthenticate({ token, newPassword }) {
    return apiRequestActionCreator({
        actionName: 'AUTHENTICATE',
        params: {
            data: { newPassword, token },
            method: 'post',
            url: 'stateful/v1/auth/password/reset',
        },
        processError: processAuthenticationError,
    });
}

export function changePassword({ currentPassword, newPassword }) {
    return apiRequestActionCreator({
        actionName: 'PASSWORD_CHANGE',
        params: {
            data: { currentPassword, newPassword },
            method: 'post',
            url: 'stateful/v1/auth/password/change',
        },
        processError: processItemError,
    });
}

export function logOut() {
    return apiRequestActionCreator({
        actionName: 'LOGOUT',
        params: {
            method: 'post',
            url: '/stateful/logout',
        },
    });
}
