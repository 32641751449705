/* eslint-disable import/prefer-default-export */

import apiRequestActionCreator from './apiRequestActionCreator';

export function fetchClientApiServices({ clientId }) {
    return apiRequestActionCreator({
        actionName: 'CLIENT_API_SERVICES',
        params: {
            url: `/stateful/v1/clients/${clientId}/apiservices`,
        },
    });
}
