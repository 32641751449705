/* eslint-disable import/prefer-default-export */

import { addItem, fetchCollection, fetchItem, updateItem } from '../collectionActions';

const fetchReminderSchedules = fetchCollection({
    apiCollectionName: 'reminderschedules',
    collectionActionName: 'REMINDER_SCHEDULES',
});

const paramsFunction = ({ params: { templates, delay, ...rest } }) => ({
    ...rest,
    delay: delay * 60,
    templates: Object.values(templates ?? {}).filter(id => id),
});

const addReminderSchedule = addItem({
    apiCollectionName: 'reminderschedules',
    itemActionName: 'REMINDER_SCHEDULE',
    paramsFunction,
});

const fetchReminderSchedule = fetchItem({
    apiCollectionName: 'reminderschedules',
    itemActionName: 'REMINDER_SCHEDULE',
});

const updateReminderSchedule = updateItem({
    apiCollectionName: 'reminderschedules',
    itemActionName: 'REMINDER_SCHEDULE',
    paramsFunction,
});

export {
    fetchReminderSchedules,
    addReminderSchedule,
    fetchReminderSchedule,
    updateReminderSchedule,
};
