/* eslint-disable import/prefer-default-export */

import { addItem, fetchCollection, fetchItem, updateItem } from '../collectionActions';

const paramsFunction = ({ params }) => ({
    ...params,
    companies: params.companies?.map(id => ({ id })) || null,
});

const fetchApmGroups = fetchCollection({
    apiCollectionName: 'apmgroups',
    apiVersion: 'v3',
    collectionActionName: 'APM_GROUPS',
});

const fetchApmGroupsOverClients = fetchCollection({
    apiCollectionName: 'apmgroups',
    collectionActionName: 'APM_GROUPS',
    useClientPrefix: false,
});

const addApmGroup = addItem({
    apiCollectionName: 'apmgroups',
    itemActionName: 'APM_GROUP',
    paramsFunction,
});

const fetchApmGroup = fetchItem({
    apiCollectionName: 'apmgroups',
    itemActionName: 'APM_GROUP',
});

const updateApmGroup = updateItem({
    apiCollectionName: 'apmgroups',
    itemActionName: 'APM_GROUP',
    paramsFunction,
});

export { addApmGroup, fetchApmGroup, fetchApmGroups, fetchApmGroupsOverClients, updateApmGroup };
