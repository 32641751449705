/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const required = value => {
    return value || typeof value === 'number' || typeof value === 'boolean' ? undefined : (
        <FormattedMessage defaultMessage="Required" id="validation.required" />
    );
};
export const matchWith = (fieldName, errorKey) => (value, values) =>
    !value || value === values[fieldName] ? undefined : <FormattedMessage id={errorKey} />;
export const atLeastOneRequired = value =>
    !value || value.length === 0 ? (
        <FormattedMessage
            defaultMessage="At least one is required"
            id="validation.atLeastOneRequired"
        />
    ) : undefined;
export const minLength = len => value =>
    !value || !value.length || value.length >= len ? undefined : (
        <FormattedMessage
            defaultMessage="Min. length {min}"
            id="validation.minLength"
            values={{ min: len }}
        />
    );
export const maxLength = len => value =>
    !value || !value.length || value.length <= len ? undefined : (
        <FormattedMessage
            defaultMessage="Max. length {max}"
            id="validation.maxLength"
            values={{ max: len }}
        />
    );
export const isInteger = value =>
    Number.isInteger(value) ? undefined : (
        <FormattedMessage defaultMessage="Should be Integer" id="validation.isInteger" />
    );
export const isAtleast = num => value => {
    return value >= num ? undefined : (
        <FormattedMessage
            defaultMessage="Should be at least {num}"
            id="validation.isAtLeastNum"
            values={{ num }}
        />
    );
};
export const isAtleastZero = isAtleast(0);
export const isLessThan = num => value =>
    value < num ? undefined : (
        <FormattedMessage
            defaultMessage="Should be less than {num}"
            id="validation.shouldBeLessThan"
            values={{ num }}
        />
    );
export const isLessThanShortInt = isLessThan(32767);
export const isLessThanMaxInt = isLessThan(2147483647);
export const isNotGreaterThan = num => value =>
    value <= num ? undefined : (
        <FormattedMessage
            defaultMessage="Cannot be greater than {num}"
            id="validation.isNotGreaterThan"
            values={{ num }}
        />
    );
export const whenDefinedMeetsConditions = conditions => value => {
    let err;
    if (value === undefined || value === null || value === '') {
        return err;
    }
    conditions.some(condition => {
        err = condition(value);
        if (err) {
            return true;
        }
        return false;
    });
    return err;
};
export const isGreaterThan = num => value =>
    value > num ? undefined : (
        <FormattedMessage
            defaultMessage="Should be greater than {num}"
            id="validation.greaterThan"
            values={{ num }}
        />
    );
export const isGreaterThanZero = isGreaterThan(0);
export const isValidRegexExpression = value => {
    try {
        RegExp(value);
    } catch (e) {
        return (
            <FormattedMessage
                defaultMessage="Enter valid regular expression"
                id="validation.isValidRegularExpression"
            />
        );
    }
    return undefined;
};
export const isValidEmail = value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value) ? (
        <FormattedMessage
            defaultMessage="Enter a valid e-mail address"
            id="validation.isValidEmail"
        />
    ) : undefined;

export const isValidPhone = value =>
    value && !/^[0-9]+$/i.test(value) ? (
        <FormattedMessage
            defaultMessage="Enter a valid phone number"
            id="validation.isValidPhone"
        />
    ) : undefined;

export const isValidInternationalPhone = value =>
    // min calling code length 1, min local part length 4, max total length 15
    value && !/^[+][0-9]{5,15}$/i.test(value) ? (
        <FormattedMessage
            defaultMessage="Enter a valid phone number"
            id="validation.isValidPhone"
        />
    ) : undefined;

export const isValidTime = value => {
    if (value instanceof Date && !Number.isNaN(value.getTime())) {
        return undefined;
    }
    return <FormattedMessage defaultMessage="Invalid time" id="validation.invalidTime" />;
};

export const isValidDate = value => {
    if (value instanceof Date && !Number.isNaN(value.getTime())) {
        return undefined;
    }
    return <FormattedMessage defaultMessage="Invalid date" id="validation.invalidDate" />;
};

export const isValidBTAddress = value =>
    value && !/^([0-9A-F]{2}[:]){5}([0-9A-F]{2})$/.test(value) ? (
        <FormattedMessage
            defaultMessage="Enter valid bluetooth address"
            id="validation.bluetoothAddress"
        />
    ) : undefined;

export const all =
    (...validators) =>
    (...validatorProps) =>
        validators.reduce((error, validator) => error || validator(...validatorProps), undefined);
