import { Button, Grid } from '@mui/material';
import { TextField } from 'cleveron-web-components';
import React, { useEffect } from 'react';
import { Field } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { reactFinalFormHOC } from '../../../../form';
import { useActions } from '../../../../redux/hooks';
import { required } from '../../../validate';

function UserActionTokenForm({ id, handleSubmit, submitting, FormHeader = null }) {
    const intl = useIntl();

    const { resetUserActionToken } = useActions();

    useEffect(() => {
        return () => {
            resetUserActionToken();
        };
    }, [resetUserActionToken]);

    return (
        <form id={id} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                {FormHeader}
                <Grid item xs={12}>
                    <Field
                        autoComplete="email"
                        component={TextField}
                        fullWidth
                        id={`${id}Email`}
                        label={intl.formatMessage({
                            defaultMessage: 'E-mail *',
                            id: 'userActionTokenForm.field.emailRequired',
                        })}
                        name="email"
                        validate={required}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        color="primary"
                        disabled={submitting}
                        fullWidth
                        id={`${id}SubmitButton`}
                        size="large"
                        type="submit"
                        variant="contained"
                    >
                        <FormattedMessage
                            defaultMessage="Confirm email"
                            id="userActionTokenForm.action.confirm"
                        />
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}

export default reactFinalFormHOC()(UserActionTokenForm);
