/* eslint-disable import/prefer-default-export */

import { PERMISSION_TYPES } from '../../constants';
import apiRequestActionCreator from '../apiRequestActionCreator';
import {
    addItem,
    fetchCollection,
    fetchItem,
    processItemError,
    updateItem,
} from '../collectionActions';

const paramsFunction = ({
    params: { officePermissions, apmPermissions, headers, slotTypes, ...rest },
}) => ({
    ...rest,
    headers: headers ? headers.reduce((acc, curr) => (curr.name ? [...acc, curr] : acc), []) : [],
    permissions: [...officePermissions, ...apmPermissions].map(permission => ({
        id: permission,
    })),
    slotTypes: slotTypes.filter(({ externalId }) => externalId),
});

const errorFunction = error => {
    const fieldErrors = error?.response?.data?.extraData;
    if (fieldErrors) {
        const transformedError = error;

        transformedError.response.data.extraData = fieldErrors.map(err => {
            let transformedFieldError = err;
            if (err.field.startsWith('permissions')) {
                if (err.values.typeId === PERMISSION_TYPES.APM) {
                    transformedFieldError = {
                        ...err,
                        field: 'apmPermissions',
                    };
                } else if (err.values.typeId === PERMISSION_TYPES.OFFICE) {
                    transformedFieldError = {
                        ...err,
                        field: 'officePermissions',
                    };
                }
            }

            return transformedFieldError;
        });

        return transformedError;
    }

    return error;
};

const fetchClients = fetchCollection({
    apiCollectionName: 'clients',
    collectionActionName: 'CLIENTS',
    useClientPrefix: false,
});

const addClient = addItem({
    apiCollectionName: 'clients',
    apiVersion: 'v2',
    errorFunction,
    itemActionName: 'CLIENT',
    paramsFunction,
    useClientPrefix: false,
});

const copyClient = ({ clientId, ...data }) => {
    return apiRequestActionCreator({
        actionName: 'CLIENT',

        params: {
            data,
            method: 'post',
            url: `/stateful/v2/clients/${clientId}/copy`,
        },
        processError: processItemError,
        rethrow: true,
    });
};

const fetchClient = fetchItem({
    apiCollectionName: 'clients',
    apiVersion: 'v2',
    itemActionName: 'CLIENT',
    useClientPrefix: false,
});

const updateClient = updateItem({
    apiCollectionName: 'clients',
    apiVersion: 'v2',
    errorFunction,
    itemActionName: 'CLIENT',
    paramsFunction,
    useClientPrefix: false,
});

export { addClient, copyClient, fetchClient, fetchClients, updateClient };
