/* eslint-disable import/prefer-default-export */

import apiRequestActionCreator from '../apiRequestActionCreator';
import { addItem, fetchCollection, fetchItem, updateItem } from '../collectionActions';

const fetchSlotGroups = fetchCollection({
    apiCollectionName: 'slot-groups',
    collectionActionName: 'SLOT_GROUPS',
});

const addSlotGroup = addItem({
    apiCollectionName: 'slot-groups',
    itemActionName: 'SLOT_GROUP',
});

const fetchSlotGroup = fetchItem({
    apiCollectionName: 'slot-groups',
    itemActionName: 'SLOT_GROUP',
});

const updateSlotGroup = updateItem({
    apiCollectionName: 'slot-groups',
    itemActionName: 'SLOT_GROUP',
});

function fetchGroupedSlots({ apmId, clientId, requestActionProps }) {
    return apiRequestActionCreator({
        actionName: 'GROUPED_SLOTS',
        params: {
            url: `/stateful/v1/clients/${clientId}/apms/${apmId}/slot-groups`,
        },
        requestActionProps,
    });
}

function saveGroupedSlots({ apmId, clientId, organizedSlots, requestActionProps }) {
    return apiRequestActionCreator({
        actionName: 'SAVE_GROUPED_SLOTS',
        params: {
            data: Object.values(
                Object.values(organizedSlots).reduce((acc, { id, slotIds }) => {
                    slotIds.forEach(slot => {
                        if (!acc[slot]) {
                            acc[slot] = {
                                slotGroups: [],
                                slotId: slot,
                            };
                        }
                        if (!acc[slot].slotGroups.some(slotGroupId => slotGroupId === id)) {
                            acc[slot].slotGroups.push(id);
                        }
                    });
                    return acc;
                }, {})
            ),
            method: 'post',
            url: `/stateful/v1/clients/${clientId}/apms/${apmId}/slot-groups`,
        },
        processSuccessResponse: (response, params, dispatch) =>
            dispatch(
                fetchGroupedSlots({
                    apmId,
                    clientId,
                    requestActionProps,
                })
            ),
    });
}

export {
    addSlotGroup,
    fetchGroupedSlots,
    fetchSlotGroup,
    fetchSlotGroups,
    saveGroupedSlots,
    updateSlotGroup,
};
