/* eslint-disable import/prefer-default-export */

import apiRequestActionCreator from '../apiRequestActionCreator';
import {
    emptyCollection,
    exportCollection,
    fetchCollection,
    fetchItem,
    importCollection,
    processItemError,
    updateItem,
} from '../collectionActions';

const fields = [
    { fieldName: 'service' },
    { fieldName: 'barcode' },
    { fieldName: 'destination.apm' },
    { fieldName: 'destination.location' },
    { fieldName: 'receiveCode' },
    { fieldName: 'slotSize' },
    { fieldName: 'hold' },
    { fieldName: 'holdExpireAt' },
    { fieldName: 'multiOrder.code' },
    { fieldName: 'multiOrder.itemCount' },
    { fieldName: 'dropOff.type' },
    { fieldName: 'dropOff.apm' },
    { fieldName: 'dropOff.code' },
    {
        excelFieldName: 'lastEvent',
        fieldName: 'lastEventContextId',
        processValue: value => {
            return (
                {
                    1: 'CREATE',
                    10: 'SLOTCHANGE',
                    11: 'OVERTIME',
                    13: 'C2CIN',
                    16: 'SIZECHANGE',
                    18: 'AGE_CONFIRMATION_SUCCESS',
                    19: 'AGE_CONFIRMATION_FAILURE',
                    2: 'OPERIN',
                    20: 'OVERTIMEDUE',
                    21: 'REMINDER',
                    22: 'LEFT_FOR_CUSTOMER_SERVICE',
                    23: 'MAILROOM_DROPOFF',
                    24: 'MAILROOM_RECEIVE',
                    3: 'OPEROUT',
                    4: 'RECEIVE',
                    5: 'RETURN',
                }[value] || ''
            );
        },
    },
    { fieldName: 'lastEventTime' },
    { fieldName: 'lastApm' },
    { fieldName: 'createdTime' },
    { fieldName: 'insertedTime' },
    { fieldName: 'dropOff.expireAt' },
    { fieldName: 'templates', processValue: value => value?.join() },
    { fieldName: 'phone' },
    { fieldName: 'email' },
    { fieldName: 'extras.description' },
    { fieldName: 'extras.receiveCodeAlt' },
    { fieldName: 'extras.companyId' },
    { fieldName: 'measurement.widthPredefined' },
    { fieldName: 'measurement.heightPredefined' },
    { fieldName: 'measurement.depthPredefined' },
    { fieldName: 'measurement.weightPredefined' },
    { fieldName: 'temperatureZone' },
    { fieldName: 'temperatureZonePredefined' },
    { fieldName: 'ageRestriction' },
    {
        fieldName: 'allocationRequired',
        processValue: value => (value !== null ? String(value) : null),
    },
    { fieldName: 'slotGroup' },
];

function updateItemParamsFunction({ params }) {
    const { blocking, company, smsNotificationId, temperatureZone, ...other } = params;
    const setDropOff = dropOff => {
        const { apmName, ...dropOffData } = dropOff;
        return {
            ...dropOffData,
            expireAt: dropOffData.expireAt && new Date(dropOffData.expireAt).toISOString(),
        };
    };
    return {
        ...other,
        company: company?.id && { id: company.id },
        dropoff:
            (params.dropoff &&
                params.dropoff.type && {
                    ...setDropOff(params.dropoff),
                }) ||
            null,
        holdExpireAt: params.holdExpireAt && new Date(params.holdExpireAt).toISOString(),
        templateIds: Object.values(params.templateIds ?? {}).filter(id => id),
        ...(blocking && {
            blocking: {
                ...blocking,
                ...(blocking.blockReasons && {
                    blockReasons: blocking.blockReasons.filter(({ text }) => text),
                }),
            },
        }),
    };
}

function addItemParamsFunction({ params }) {
    return {
        ...params,
        destination: {
            apmId: params.destination.apmId || null,
        },
        hold: params.hold >= 0 ? Math.round(1440 * params.hold) : null, // days to minutes
        templateIds: Object.values(params.templateIds ?? {}).filter(id => id),
    };
}

const fetchOrders = fetchCollection({
    apiCollectionName: 'orders',
    apiVersion: 'v3',
    collectionActionName: 'ORDERS',
});

const fetchOrdersOverAllClients = fetchCollection({
    apiCollectionName: 'orders',
    collectionActionName: 'ORDERS',
    useClientPrefix: false,
});

const addOrder = ({ clientId, ...params } = {}) =>
    apiRequestActionCreator({
        actionName: 'ORDER',
        params: {
            data: addItemParamsFunction({ params }),
            method: 'post',
            url: `/stateful/v1/clients/${clientId}/orders`,
        },
        processError: processItemError,
        processSuccessResponse: ({ data }) => ({ id: data.id }),
    });

const fetchOrder = fetchItem({
    apiCollectionName: 'orders',
    itemActionName: 'ORDER',
});

const updateOrder = updateItem({
    apiCollectionName: 'orders',
    itemActionName: 'ORDER',
    paramsFunction: updateItemParamsFunction,
});

function ordersImportFailure(payload) {
    return { payload, type: 'ORDERS_IMPORT_FAILURE' };
}

function ordersImportStart(payload) {
    return { payload, type: 'ORDERS_IMPORT_START' };
}

function resetOrdersImport() {
    return { type: 'ORDERS_IMPORT_RESET' };
}

const importOrders = importCollection({
    apiCollectionName: 'orders',
    collectionActionName: 'ORDERS_IMPORT',
});

function fieldToValue(field, order) {
    const processValue = field.processValue || (value => value);
    if (field.fieldName.includes('.')) {
        const [mainKey, objectKey] = field.fieldName.split('.');
        return order[mainKey] ? processValue(order[mainKey][objectKey]) : null;
    }
    return processValue(order[field.fieldName]);
}

const exportOrders = exportCollection({
    apiCollectionName: 'orders',
    apiVersion: 'v2',
    collectionActionName: 'ORDERS_EXPORT',
    exportDataToArrayOfArrays: data => [
        fields.map(field => field.excelFieldName || field.fieldName),
        ...data.ordersList.map(order => fields.map(field => fieldToValue(field, order))),
    ],
    exportFileName: 'orders',
});

const emptyOrders = emptyCollection({
    collectionActionName: 'ORDERS',
});

const resendNotification = ({ clientId, orderId, successSnackbarProps, ...params } = {}) =>
    apiRequestActionCreator({
        actionName: 'RESEND_NOTIFICATION',
        params: {
            data: params,
            method: 'post',
            url: `/stateful/v1/clients/${clientId}/orders/${orderId}/notifications/resend`,
        },
        processError: error => {
            processItemError(error);
            return error;
        },
        rethrow: true,
        successSnackbarProps,
    });

const createReminder = ({ clientId, orderId, successSnackbarProps, ...params } = {}) =>
    apiRequestActionCreator({
        actionName: 'CREATE_REMINDER',
        params: {
            data: params,
            method: 'post',
            url: `/stateful/v1/clients/${clientId}/orders/${orderId}/notifications/reminder`,
        },
        processError: error => {
            processItemError(error);
            return error;
        },
        rethrow: true,
        successSnackbarProps,
    });

export {
    addOrder,
    emptyOrders,
    exportOrders,
    fetchOrder,
    fetchOrders,
    fetchOrdersOverAllClients,
    importOrders,
    resetOrdersImport,
    ordersImportFailure,
    ordersImportStart,
    updateOrder,
    resendNotification,
    createReminder,
};
