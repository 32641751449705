/* eslint-disable import/prefer-default-export */

import apiRequestActionCreator from './apiRequestActionCreator';

export function fetchApiServices() {
    return apiRequestActionCreator({
        actionName: 'API_SERVICES',
        params: {
            url: `/stateful/v1/apiservices`,
        },
    });
}
