import format from 'date-fns/format';

import { addItem, fetchCollection, fetchItem, updateItem } from '../collectionActions';

const paramsFunction = ({ params }) => ({
    ...params,
    serviceHours: params.serviceHours
        ? params.serviceHours.reduce(
              (acc, curr) =>
                  curr.startTime && curr.endTime && curr.isoWeekDays
                      ? [
                            ...acc,
                            {
                                ...curr,
                                endTime: format(curr.endTime, 'HH:mm'),
                                startTime: format(curr.startTime, 'HH:mm'),
                            },
                        ]
                      : acc,
              []
          )
        : [],
});

const fetchApmServiceHours = fetchCollection({
    apiCollectionName: 'apmservicehours',
    collectionActionName: 'APM_SERVICE_HOURS',
});

const addApmServiceHoursItem = addItem({
    apiCollectionName: 'apmservicehours',
    itemActionName: 'APM_SERVICE_HOURS_ITEM',
    paramsFunction,
});

const fetchApmServiceHoursItem = fetchItem({
    apiCollectionName: 'apmservicehours',
    itemActionName: 'APM_SERVICE_HOURS_ITEM',
});

const updateApmServiceHoursItem = updateItem({
    apiCollectionName: 'apmservicehours',
    itemActionName: 'APM_SERVICE_HOURS_ITEM',
    paramsFunction,
});

export {
    addApmServiceHoursItem,
    fetchApmServiceHoursItem,
    fetchApmServiceHours,
    updateApmServiceHoursItem,
};
