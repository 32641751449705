/* eslint-disable import/prefer-default-export */

import externalIdParamsFunction from '../../App/CompanyExternalId/externalIdParamsFunction';
import {
    addItem,
    deleteItemFieldValue,
    exportCollection,
    fetchCollection,
    fetchItem,
    importCollection,
    updateItem,
} from '../collectionActions';

const fields = [
    { fieldName: 'status', processValue: value => (value ? 'Active' : 'Inactive') },
    { fieldName: 'name' },
    { fieldName: 'email' },
    { fieldName: 'officeRoleNames', processValue: value => value.join(',') },
    { fieldName: 'restrictedUser', processValue: value => (value ? 'true' : 'false') },
    { fieldName: 'apmAccessCode' },
    { fieldName: 'companyNames', processValue: value => value.join(',') },
    { fieldName: 'apmRoleNames', processValue: value => value.join(',') },
    { fieldName: 'apmGroupNames', processValue: value => value.join(',') },
    { fieldName: 'externalId' },
];

const fetchUsers = fetchCollection({
    apiCollectionName: 'persons',
    apiVersion: 'v2',
    collectionActionName: 'USERS',
});

const paramsFunction = ({ params: { companyId, restrictedUser, officeRoles, ...params } }) =>
    externalIdParamsFunction({
        params: {
            ...params,
            apmRoles: params.apmRoles
                ? params.apmRoles.reduce((acc, curr) => {
                      if (curr.id) {
                          return [...acc, { ...curr, companyId }];
                      }
                      return acc;
                  }, [])
                : [],
            officeRoles:
                officeRoles?.map(id => ({
                    companyId: restrictedUser ? companyId : null,
                    id,
                })) || [],
        },
    });

const addUser = addItem({
    apiCollectionName: 'persons',
    itemActionName: 'USER',
    paramsFunction,
});

const fetchUser = fetchItem({
    apiCollectionName: 'persons',
    itemActionName: 'USER',
});

const updateUser = updateItem({
    apiCollectionName: 'persons',
    itemActionName: 'USER',
    paramsFunction,
});

function usersImportFailure(payload) {
    return { payload, type: 'USERS_IMPORT_FAILURE' };
}

function usersImportStart(payload) {
    return { payload, type: 'USERS_IMPORT_START' };
}

function resetUsersImport() {
    return { type: 'USERS_IMPORT_RESET' };
}

const importUsers = importCollection({
    apiCollectionName: 'persons',
    collectionActionName: 'USERS_IMPORT',
});

function fieldToValue(field, order) {
    const processValue = field.processValue || (value => value);
    if (field.fieldName.includes('.')) {
        const [mainKey, objectKey] = field.fieldName.split('.');
        return order[mainKey] ? processValue(order[mainKey][objectKey]) : null;
    }
    return processValue(order[field.fieldName]);
}

const exportUsers = exportCollection({
    apiCollectionName: 'persons',
    apiVersion: 'v2',
    collectionActionName: 'USERS_EXPORT',
    exportDataToArrayOfArrays: data => [
        fields.map(field => field.excelFieldName || field.fieldName),
        ...data.personsList.map(order => fields.map(field => fieldToValue(field, order))),
    ],
    exportFileName: 'users',
});

const deleteUserFieldValue = deleteItemFieldValue({
    apiCollectionName: 'persons',
    itemActionName: 'USER',
});

export {
    addUser,
    deleteUserFieldValue,
    exportUsers,
    fetchUser,
    fetchUsers,
    importUsers,
    resetUsersImport,
    updateUser,
    usersImportFailure,
    usersImportStart,
};
