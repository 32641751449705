/* eslint-disable import/prefer-default-export */

import apiRequestActionCreator from './apiRequestActionCreator';

export function fetchSlotsLimits({ apmId, clientId }) {
    return apiRequestActionCreator({
        actionName: 'SLOTS_LIMITS',
        params: {
            url: `/stateful/v1/clients/${clientId}/apms/${apmId}/slotslimits`,
        },
    });
}

export function setSlotsLimits(payload) {
    return { payload, type: 'SET_SLOTS_LIMITS' };
}

export function saveSlotsLimits({ apmId, clientId, slotsLimits }) {
    return apiRequestActionCreator({
        actionName: 'SAVE_SLOTS_LIMITS',
        params: {
            data: slotsLimits.companies,
            method: 'post',
            url: `/stateful/v1/clients/${clientId}/apms/${apmId}/slotslimits`,
        },
    });
}
