import { Grid } from '@mui/material';
import { ReactMarkdown, Select } from 'cleveron-web-components';
import getUserLocale from 'get-user-locale';
import React from 'react';
import { Field, FormSpy } from 'react-final-form';
import { connect } from 'react-redux';

import { reactFinalFormHOC } from '../../form';

function PrivacyPolicyStatement({ id, privacyPolicy }) {
    return (
        <Grid container spacing={2}>
            <Grid item lg={4} sm={6} xs={12}>
                <Field
                    component={Select}
                    id={`${id}Language`}
                    isClearable={false}
                    label="Language"
                    name="policyId"
                    options={privacyPolicy.translations.map(({ localeDescription, id: lid }) => ({
                        label: localeDescription,
                        value: lid,
                    }))}
                />
            </Grid>
            <Grid item xs={12}>
                <FormSpy subscription={{ values: true }}>
                    {({ values: { policyId } }) => (
                        <ReactMarkdown
                            id={`${id}PrivacyPolicy`}
                            markdown={
                                privacyPolicy.translations.find(({ id: pid }) => pid === policyId)
                                    ?.content
                            }
                        />
                    )}
                </FormSpy>
            </Grid>
        </Grid>
    );
}

const PrivacyPolicyStatementForm = reactFinalFormHOC()(PrivacyPolicyStatement);

const ConnectedPrivacyPolicyStatementForm = connect(({ privacyPolicy }) => {
    const userLocale = getUserLocale();
    const byUserLocale =
        userLocale &&
        privacyPolicy.translations.find(({ locale }) =>
            locale.includes(userLocale.replace('-', '_'))
        )?.id;

    const byPreferringEnglish = privacyPolicy.translations.find(({ locale }) =>
        locale.includes('en-GB')
    )?.id;

    return {
        initialValues: {
            policyId: byUserLocale || byPreferringEnglish || privacyPolicy.translations[0]?.id,
        },
        privacyPolicy,
    };
})(PrivacyPolicyStatementForm);

export default ConnectedPrivacyPolicyStatementForm;
