/* eslint-disable import/prefer-default-export */
import apiRequestActionCreator from './apiRequestActionCreator';

export function fetchSlotTypes() {
    return apiRequestActionCreator({
        actionName: 'SLOT_TYPES',
        params: {
            url: `/stateful/v1/slottypes`,
        },
    });
}
