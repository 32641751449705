/* eslint-disable import/prefer-default-export */

import apiRequestActionCreator from './apiRequestActionCreator';

export function fetchClientPermissions({ clientId }) {
    return apiRequestActionCreator({
        actionName: 'CLIENT_PERMISSIONS',
        params: {
            url: `/stateful/v1/clients/${clientId}/permissions`,
        },
    });
}
