/* eslint-disable import/prefer-default-export */

import { addItem, fetchCollection, fetchItem, updateItem } from '../collectionActions';

const fetchClientGroups = fetchCollection({
    apiCollectionName: 'clientgroups',
    collectionActionName: 'CLIENT_GROUPS',
    useClientPrefix: false,
});

const addClientGroup = addItem({
    apiCollectionName: 'clientgroups',
    itemActionName: 'CLIENT_GROUP',
    useClientPrefix: false,
});

const fetchClientGroup = fetchItem({
    apiCollectionName: 'clientgroups',
    itemActionName: 'CLIENT_GROUP',
    useClientPrefix: false,
});

const updateClientGroup = updateItem({
    apiCollectionName: 'clientgroups',
    itemActionName: 'CLIENT_GROUP',
    useClientPrefix: false,
});

export { addClientGroup, fetchClientGroup, fetchClientGroups, updateClientGroup };
