/* eslint-disable import/prefer-default-export */

import apiRequestActionCreator from './apiRequestActionCreator';

export function fetchPatternTypes() {
    return apiRequestActionCreator({
        actionName: 'PATTERNTYPES',
        params: {
            url: '/stateful/v1/patterntypes',
        },
    });
}
