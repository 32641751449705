import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFormState } from 'react-final-form';
import { FormattedMessage } from 'react-intl';

import AuthPageTitle from '../AuthPageTitle';

function AuthFormHeader({ id, titleKey, subtitleKey, initialSubmitError }) {
    const [submitError, setSubmitError] = useState(initialSubmitError);
    const { submitError: formSubmitError } = useFormState({
        submitError: true,
    });

    useEffect(() => {
        if (formSubmitError) {
            setSubmitError(formSubmitError);
        }
    }, [formSubmitError]);

    return (
        <>
            <Grid item xs={12}>
                <AuthPageTitle subtitleKey={submitError ? null : subtitleKey} titleKey={titleKey} />
            </Grid>
            {submitError && (
                <Grid item xs={12}>
                    <Typography color="error" gutterBottom id={`${id}Message`} variant="subtitle1">
                        <FormattedMessage id={submitError.id} />
                    </Typography>
                </Grid>
            )}
        </>
    );
}

export default AuthFormHeader;
