import { Container } from '@mui/material';
import { Page } from 'cleveron-web-components';
import React, { useState } from 'react';
import { defineMessage } from 'react-intl';

import { USER_ACTION_TOKEN_TYPES } from '../../../../constants';
import { useActions, useSelector } from '../../../../redux/hooks';
import AuthFormHeader from '../../../ConnectedAuthWrapper/AuthFormHeader';
import AuthPageTitle from '../../../ConnectedAuthWrapper/AuthPageTitle';
import UserActionTokenForm from '../UserActionTokenForm';

function RegistrationToken({ id }) {
    const { locale, userActionToken } = useSelector(state => ({
        locale: state.locale,
        userActionToken: state.userActionToken,
    }));
    const { requestUserActionToken } = useActions();

    const [submitSucceeded, setSubmitSucceeded] = useState(false);

    return (
        <Page
            id={id}
            titleKey={
                defineMessage({ defaultMessage: 'Verify account', id: 'page.registration' }).id
            }
        >
            <Container maxWidth="sm">
                {submitSucceeded ? (
                    <AuthPageTitle
                        id={`${id}TitleSuccess`}
                        titleKey={
                            defineMessage({
                                defaultMessage: 'Confirmation link was sent to your e-mail!',
                                id: 'registration.text.tokenEmailSent',
                            }).id
                        }
                    />
                ) : (
                    <UserActionTokenForm
                        FormHeader={
                            <AuthFormHeader
                                id={`${id}TokenFormHeader`}
                                initialSubmitError={
                                    userActionToken?.error &&
                                    defineMessage({
                                        defaultMessage:
                                            'E-mail confirmation link has expired. Request a new link by entering your email.',
                                        id: 'registration.error.tokenExpired',
                                    })
                                }
                                titleKey={
                                    defineMessage({
                                        defaultMessage: 'Activate account with e-mail',
                                        id: 'registration.text.activateAccount',
                                    }).id
                                }
                            />
                        }
                        id={`${id}TokenForm`}
                        onSubmit={props =>
                            requestUserActionToken({
                                ...props,
                                locale,
                                type: USER_ACTION_TOKEN_TYPES.REGISTRATION,
                            })
                        }
                        onSubmitSuccess={() => setSubmitSucceeded(true)}
                    />
                )}
            </Container>
        </Page>
    );
}

export default RegistrationToken;
