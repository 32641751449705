import { InvertColors as InvertColorsIcon } from '@mui/icons-material';
import { AppBar, Box, Button, IconButton, Toolbar, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CleverOfficeLogo } from 'cleveron-web-components';
import React from 'react';
import { Redirect, useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import { useActions, useSelector } from '../../redux/hooks';
import LocaleSelector from '../LocaleSelector';
import Error408 from '../statuses/Error408';
import AuthPrivacyPolicyDialog from './AuthPrivacyPolicyDialog';
import CleverOfficeAuthBackgroundDark from './cleveroffice-auth-bg-dark.png';
import CleverOfficeAuthBackground from './cleveroffice-auth-bg.png';

const AUTH_LAYOUT_PROPS = {
    backgroundImage: CleverOfficeAuthBackground,
    backgroundImageDark: CleverOfficeAuthBackgroundDark,
    side: {
        width: '650px',
    },
};

const useStyles = props =>
    makeStyles(theme => ({
        '@global': {
            [theme.breakpoints.up('lg')]: {
                '.MuiDialog-container': {
                    marginLeft: props.side.width,
                },
            },
        },
        appBar: {
            background: 'transparent',
            boxShadow: 'none',
            color: theme.palette.text.primary,
        },
        content: {
            height: '100%',
            [theme.breakpoints.up('lg')]: {
                marginLeft: props.side.width,
            },
        },
        logo: {
            color: theme.palette.text.primary,
            height: 24,
            [theme.breakpoints.down('lg')]: {
                height: 21,
            },
        },
        logoButton: {
            [theme.breakpoints.down('lg')]: {
                marginLeft: '-8px',
            },
        },
        logoContainer: {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyItems: 'center',
            [theme.breakpoints.down('lg')]: {
                alignItems: 'flex-start',
            },
            overflow: 'hidden',
        },
        root: {
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary,
            minHeight: '100%',
        },
        sideContainer: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'center',
        },
        sideRoot: {
            backgroundColor: theme.isDark() ? '#343434' : '#0077c80d',
            backgroundImage: `url(${
                theme.isDark() ? props.backgroundImageDark : props.backgroundImage
            })`,
            backgroundOrigin: 'content-box',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'initial',
            height: '100%',
            left: 0,
            margin: 0,
            position: 'absolute',
            top: 0,
            width: props.side.width,
            [theme.breakpoints.down('lg')]: {
                display: 'none',
            },
        },

        toolbar: theme.mixins.toolbar,
    }));

function LogoButton({ id, classes, version }) {
    return (
        <div className={classes.logoContainer}>
            <Button
                className={classes.logoButton}
                component={Link}
                disableFocusRipple
                disableRipple
                id={`${id}LogoButton`}
                to="/login"
            >
                <CleverOfficeLogo className={classes.logo} id={`${id}ToolbarLogo`} />
            </Button>
            {version && (
                <Typography
                    className={classes.version}
                    color="textSecondary"
                    id={`${id}Version`}
                    variant="caption"
                >
                    {version}
                </Typography>
            )}
        </div>
    );
}

function AuthWrapper({ id, children }) {
    const classes = useStyles(AUTH_LAYOUT_PROPS)();
    const isLargeScreen = useMediaQuery(theme => theme.breakpoints.up('lg'));
    const location = useLocation();
    const { error, user, version } = useSelector(state => ({
        error: state.me?.error,
        user: state.me?.user,
        version: state.version,
    }));
    const { toggleDarkMode } = useActions();

    if (error?.message === 'Network Error') {
        return <Error408 id={`${id}Error408`} route={`${location.pathname}${location.search}`} />;
    }
    if (user) {
        return <Redirect to={{ pathname: '/', search: location.search }} />;
    }

    return (
        <div className={classes.root} id={id}>
            <div className={classes.sideRoot}>
                <div className={classes.sideContainer}>
                    <LogoButton classes={classes} version={version} />
                </div>
            </div>
            <main className={classes.content}>
                <AppBar className={classes.appBar} position="static">
                    <Toolbar className={classes.toolbar}>
                        {!isLargeScreen && <LogoButton classes={classes} version={version} />}
                        <Box sx={{ flexGrow: 1 }} />
                        <LocaleSelector />
                        <IconButton
                            id={`${id}ToggleDarkModeButton`}
                            onClick={() => toggleDarkMode()}
                            size="large"
                        >
                            <InvertColorsIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                {children}
                <AuthPrivacyPolicyDialog id={`${id}PrivacyPolicyDialog`} />
            </main>
        </div>
    );
}

export default AuthWrapper;
