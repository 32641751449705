/* eslint-disable import/prefer-default-export */
import isEqual from 'lodash/isEqual';
import mapValues from 'lodash/mapValues';
import { useMemo } from 'react';
import { useDispatch, useSelector as useReduxSelector } from 'react-redux';

import * as allActions from '../actions';

export function useSelector(selector) {
    return useReduxSelector(selector, isEqual);
}

export function useActions(actions = allActions) {
    const dispatch = useDispatch();
    return useMemo(
        () =>
            mapValues(
                actions,
                action =>
                    (...args) =>
                        dispatch(action(...args))
            ),
        [dispatch, actions]
    );
}
