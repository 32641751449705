import apiRequestActionCreator from './apiRequestActionCreator';
import { processAuthenticationError } from './authentication';

export function fetchUserActionToken({ token, type }) {
    return apiRequestActionCreator({
        actionName: 'USER_ACTION_TOKEN',
        params: {
            data: { token, type },
            method: 'put',
            url: `stateful/v1/auth/user/token`,
        },
    });
}

export function requestUserActionToken(data) {
    return apiRequestActionCreator({
        actionName: 'REQUEST_USER_ACTION_TOKEN',
        params: {
            data,
            method: 'post',
            url: `stateful/v1/auth/user/token`,
        },
        processError: processAuthenticationError,
    });
}

export function resetUserActionToken() {
    return dispatch => {
        dispatch({
            type: 'RESET_USER_ACTION_TOKEN',
        });
    };
}
