import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(theme => ({
    container: {
        width: 'auto',
        wordBreak: 'break-all',
    },
    root: {
        display: 'flex',
        lineHeight: 0,
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(5),
        minHeight: 56,
    },
    subtitle: {
        color: theme.palette.text.disabled,
        marginTop: theme.spacing(1),
        wordBreak: 'break-word',
    },
}));

export default function AuthPageTitle({ className, id, title, titleKey, subtitle, subtitleKey }) {
    const classes = useStyles();

    return (
        <div className={classNames(classes.root, className)} id={id}>
            <Grid
                alignItems="center"
                container
                justifyContent="space-around"
                spacing={1}
                wrap="wrap"
            >
                <Grid className={classes.container} container item wrap="nowrap">
                    <Grid
                        alignItems="center"
                        className={classes.container}
                        container
                        item
                        spacing={1}
                        wrap="wrap"
                    >
                        <Grid item>
                            <Typography align="center" id={`${id}Title`} variant="h5">
                                {title || <FormattedMessage id={titleKey} />}
                            </Typography>
                            {(subtitle || subtitleKey) && (
                                <Typography
                                    align="center"
                                    className={classes.subtitle}
                                    id={`${id}Subtitle`}
                                    variant="subtitle1"
                                >
                                    {subtitle || <FormattedMessage id={subtitleKey} />}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}
