/* eslint-disable import/prefer-default-export */

import apiRequestActionCreator from './apiRequestActionCreator';

export function fetchPasswordCategories() {
    return apiRequestActionCreator({
        actionName: 'PASSWORD_CATEGORIES',
        params: {
            url: '/stateful/v1/passwordcategories',
        },
    });
}
