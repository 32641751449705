/* eslint-disable import/prefer-default-export */

import { arrayToString } from 'cleveron-web-components/utils/arrays';
import { hasValue } from 'cleveron-web-components/utils/validators';
import { defineMessage } from 'react-intl';

import apiRequestActionCreator from './apiRequestActionCreator';
import { fetchLayout } from './apmLayout';
import { processItemError } from './collectionActions';

const mailroomUserParamsFunction = data => {
    const { notificationEmails, notificationPhones, ...other } = data;
    const nonEmptyNotificationEmails =
        notificationEmails?.filter(email => hasValue(email?.value)).map(({ value }) => value) || [];
    const nonEmptyNotificationPhones =
        notificationPhones?.filter(phone => hasValue(phone?.value)).map(({ value }) => value) || [];

    return {
        ...other,
        notificationEmails: nonEmptyNotificationEmails.length ? nonEmptyNotificationEmails : null,
        notificationPhones: nonEmptyNotificationPhones.length ? nonEmptyNotificationPhones : null,
    };
};

export function fetchMailroom({ id, clientId }) {
    return apiRequestActionCreator({
        actionName: 'MAILROOM',
        params: {
            url: `/stateful/v1/clients/${clientId}/apms/${id}/mailroom`,
        },
    });
}

export function fetchMailroomEvents({ apmId, clientId, page, ...other }) {
    const defaultParams = { ...other, offset: page - 1 || 0 };
    return apiRequestActionCreator({
        actionName: 'MAILROOM_EVENTS',
        params: {
            params: defaultParams,
            url: `/stateful/v1/clients/${clientId}/apms/${apmId}/mailrooms/events`,
        },
    });
}

export function addMailroomUser({ apmId, clientId, data }) {
    return apiRequestActionCreator({
        actionName: 'SAVE_MAILROOM_USER',
        blockParallel: true,
        params: {
            data: mailroomUserParamsFunction(data),
            method: 'post',
            url: `stateful/v1/clients/${clientId}/apms/${apmId}/mailroom/users`,
        },
        processError: processItemError,
        processSuccessResponse: (response, params, dispatch) =>
            dispatch(
                fetchMailroom({
                    clientId,
                    id: apmId,
                })
            ),
    });
}

export function saveMailroomUser({ apmId, clientId, data, mailroomUserId }) {
    return apiRequestActionCreator({
        actionName: 'SAVE_MAILROOM_USER',
        blockParallel: true,
        params: {
            data: mailroomUserParamsFunction(data),
            method: 'put',
            url: `/stateful/v1/clients/${clientId}/apms/${apmId}/mailroom/users/${mailroomUserId}`,
        },
        processSuccessResponse: (response, params, dispatch) =>
            dispatch(
                fetchMailroom({
                    clientId,
                    id: apmId,
                })
            ),
    });
}

export function generateNewMailroomUserReceiveCode({ apmId, clientId, mailroomUserId }) {
    return apiRequestActionCreator({
        actionName: 'GENERATE_NEW_MAILROOM_USER_RECEIVE_CODE',
        blockParallel: true,
        params: {
            method: 'put',
            url: `/stateful/v1/clients/${clientId}/apms/${apmId}/mailroom/users/${mailroomUserId}/receive-code`,
        },
        rethrow: true,
        successSnackbarProps: {
            message: {
                id: defineMessage({
                    defaultMessage: 'Generated',
                    id: 'actions.generated',
                }).id,
            },
        },
    });
}

export function deleteMailroomUsers({ apmId, clientId, mailroomUsersIds }) {
    return apiRequestActionCreator({
        actionName: 'DELETE_MAILROOM_USERS',
        blockParallel: true,
        params: {
            method: 'delete',
            params: {
                ids: arrayToString(mailroomUsersIds),
            },
            url: `/stateful/v1/clients/${clientId}/apms/${apmId}/mailroom/users`,
        },
        processSuccessResponse: (response, params, dispatch) =>
            dispatch(
                fetchMailroom({
                    clientId,
                    id: apmId,
                })
            ),
        successSnackbarProps: {
            message: {
                id: defineMessage({
                    defaultMessage: 'Deleted',
                    id: 'actions.deleted',
                }).id,
            },
        },
    });
}

export function saveMailroomSlots({ apmId, clientId, organizedSlots }) {
    return apiRequestActionCreator({
        actionName: 'SAVE_MAILROOM_SLOTS',
        blockParallel: true,
        params: {
            data: Object.values(
                Object.values(organizedSlots).reduce((acc, { id: mailroomUserId, slotIds }) => {
                    slotIds.forEach(slot => {
                        if (!acc[slot]) {
                            acc[slot] = {
                                id: slot,
                                mailroomUserIds: [],
                            };
                        }
                        if (
                            !acc[slot].mailroomUserIds.some(
                                slotGroupId => slotGroupId === mailroomUserId
                            )
                        ) {
                            acc[slot].mailroomUserIds.push(mailroomUserId);
                        }
                    });
                    return acc;
                }, {})
            ),
            method: 'put',
            url: `/stateful/v1/clients/${clientId}/apms/${apmId}/mailroom/slots`,
        },
        processSuccessResponse: (response, params, dispatch) =>
            dispatch(
                fetchMailroom({
                    clientId,
                    id: apmId,
                })
            ),
    });
}

export function mailroomReceive({ apmId, slotIds, clientId }) {
    return apiRequestActionCreator({
        actionName: 'MAILROOM_RECEIVE',
        blockParallel: true,
        params: {
            data: { slotIds },
            method: 'post',
            url: `/stateful/v1/clients/${clientId}/apms/${apmId}/mailroom/receive`,
        },
        processSuccessResponse: (response, params, dispatch) =>
            setTimeout(() => dispatch(fetchLayout({ apmId, clientId })), 3000),
    });
}
