/* eslint-disable import/prefer-default-export */

import apiRequestActionCreator from './apiRequestActionCreator';

export function fetchTemplateTypes({ clientId }) {
    return apiRequestActionCreator({
        actionName: 'TEMPLATE_TYPES',
        params: {
            url: `/stateful/v1/clients/${clientId}/templatetypes`,
        },
    });
}
