/* eslint-disable import/prefer-default-export */

import { addItem, fetchCollection, fetchItem, updateItem } from '../collectionActions';

const fetchApmThemes = fetchCollection({
    apiCollectionName: 'uithemes',
    collectionActionName: 'APM_THEMES',
});

const paramsFunction = ({ params: { device, screen, ...params } }) => params;

const addApmTheme = addItem({
    apiCollectionName: 'uithemes',
    itemActionName: 'APM_THEME',
    paramsFunction,
});

const fetchApmTheme = fetchItem({
    apiCollectionName: 'uithemes',
    itemActionName: 'APM_THEME',
});

const updateApmTheme = updateItem({
    apiCollectionName: 'uithemes',
    itemActionName: 'APM_THEME',
    paramsFunction,
});

export { addApmTheme, fetchApmTheme, fetchApmThemes, updateApmTheme };
