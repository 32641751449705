/* eslint-disable import/prefer-default-export */

import apiRequestActionCreator from './apiRequestActionCreator';
import { fetchLayout } from './apmLayout';

export function selectSlots(payload) {
    return { payload, type: 'SELECTED_SLOTS' };
}

export function deselectAllSlots() {
    return { type: 'DESELECTED_ALL_SLOTS' };
}

export function openSlots({ apmId, slotIds, clientId }) {
    return apiRequestActionCreator({
        actionName: 'OPEN_SLOTS',
        blockParallel: true,
        params: {
            data: { slotIds },
            method: 'post',
            url: `/stateful/v1/clients/${clientId}/apms/${apmId}/slots/open`,
        },
        processSuccessResponse: (response, params, dispatch) =>
            setTimeout(() => dispatch(fetchLayout({ apmId, clientId })), 3000),
    });
}

export function saveSlotStatus({ apmId, clientId, slotIds, status }) {
    return apiRequestActionCreator({
        actionName: 'SAVE_SLOT_STATUS',
        blockParallel: true,
        params: {
            data: { slotIds, status },
            method: 'post',
            url: `/stateful/v1/clients/${clientId}/apms/${apmId}/slots/status/change`,
        },
        processSuccessResponse: (response, params, dispatch) =>
            dispatch(fetchLayout({ apmId, clientId })),
    });
}
