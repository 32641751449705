import { fetchStatistics, resetStatistics } from '../statisticsActions';

const resetEvents = resetStatistics({
    statisticsActionName: 'EVENTS',
});

const fetchEvents = fetchStatistics({
    apiPathname: 'events',
    statisticsActionName: 'EVENTS',
});

const fetchEventsOverClients = fetchStatistics({
    apiPathname: 'events',
    statisticsActionName: 'EVENTS',
    useClientPrefix: false,
});

export { fetchEvents, fetchEventsOverClients, resetEvents };
