/* eslint-disable import/prefer-default-export */

import {
    addItem,
    deleteItemFieldValue,
    fetchCollection,
    fetchItem,
    updateItem,
} from '../collectionActions';

const paramsFunction = ({ params: { officeRoleIds, ...params } }) => ({
    ...params,
    officeRoleIds: officeRoleIds ? [officeRoleIds] : [],
});

const fetchAdministrators = fetchCollection({
    apiCollectionName: 'administrators',
    collectionActionName: 'ADMINISTRATORS',
    useClientPrefix: false,
});

const addAdministrator = addItem({
    apiCollectionName: 'administrators',
    itemActionName: 'ADMINISTRATOR',
    paramsFunction,
    useClientPrefix: false,
});

const fetchAdministrator = fetchItem({
    apiCollectionName: 'administrators',
    itemActionName: 'ADMINISTRATOR',
    useClientPrefix: false,
});

const updateAdministrator = updateItem({
    apiCollectionName: 'administrators',
    itemActionName: 'ADMINISTRATOR',
    paramsFunction,
    useClientPrefix: false,
});

const deleteAdministratorFieldValue = deleteItemFieldValue({
    apiCollectionName: 'administrators',
    itemActionName: 'ADMINISTRATOR',
    useClientPrefix: false,
});

export {
    fetchAdministrators,
    addAdministrator,
    fetchAdministrator,
    updateAdministrator,
    deleteAdministratorFieldValue,
};
