/* eslint-disable import/prefer-default-export */

import apiRequestActionCreator from './apiRequestActionCreator';

export function fetchLocationSlots({ clientId, apmLocationId }) {
    return apiRequestActionCreator({
        actionName: 'LOCATION_SLOTS',
        params: {
            url: `/stateful/v1/clients/${clientId}/apmlocations/${apmLocationId}/slots`,
        },
    });
}

export function resetLocationSlots() {
    return { type: 'LOCATION_SLOTS_RESET' };
}
