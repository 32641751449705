import { ConfirmDialog } from 'cleveron-web-components';
import React from 'react';
import { defineMessage } from 'react-intl';

import { useActions, useSelector } from '../../../redux/hooks';
import PrivacyPolicyStatement from '../../PrivacyPolicyStatement';

function AuthPrivacyPolicyDialog({ id }) {
    const { privacyPolicy } = useSelector(state => ({
        privacyPolicy: state.privacyPolicy,
    }));
    const { closePrivacyPolicy } = useActions();

    return (
        <ConfirmDialog
            cancelButtonProps={{ variant: null }}
            cancelKey={
                defineMessage({
                    defaultMessage: 'Close',
                    id: 'actions.close',
                }).id
            }
            confirmKey={
                defineMessage({
                    defaultMessage: 'Confirm',
                    id: 'actions.confirm',
                }).id
            }
            fullWidth
            id={`${id}`}
            onClose={closePrivacyPolicy}
            open={privacyPolicy.open}
        >
            <PrivacyPolicyStatement id={`${id}Statement`} />
        </ConfirmDialog>
    );
}

export default AuthPrivacyPolicyDialog;
