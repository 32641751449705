/* eslint-disable import/prefer-default-export */

import apiRequestActionCreator from './apiRequestActionCreator';

export function fetchContexts() {
    return apiRequestActionCreator({
        actionName: 'CONTEXTS',
        params: {
            url: `/stateful/v1/contexts`,
        },
    });
}
