/* eslint-disable import/prefer-default-export */

import { addItem, fetchCollection, fetchItem, updateItem } from '../collectionActions';

const fetchAdministratorRoles = fetchCollection({
    apiCollectionName: 'administratorroles',
    collectionActionName: 'ADMINISTRATOR_ROLES',
    useClientPrefix: false,
});

const addAdministratorRole = addItem({
    apiCollectionName: 'administratorroles',
    itemActionName: 'ADMINISTRATOR_ROLE',
    useClientPrefix: false,
});

const fetchAdministratorRole = fetchItem({
    apiCollectionName: 'administratorroles',
    itemActionName: 'ADMINISTRATOR_ROLE',
    useClientPrefix: false,
});

const updateAdministratorRole = updateItem({
    apiCollectionName: 'administratorroles',
    itemActionName: 'ADMINISTRATOR_ROLE',
    useClientPrefix: false,
});

export {
    fetchAdministratorRoles,
    addAdministratorRole,
    fetchAdministratorRole,
    updateAdministratorRole,
};
