/* eslint-disable import/prefer-default-export */

export function openModal(payload) {
    return { payload, type: 'OPEN_MODAL' };
}

export function closeModal(payload) {
    return { payload, type: 'CLOSE_MODAL' };
}

export function emptyModal(payload) {
    return { payload, type: 'EMPTY_SLOT' };
}
