import { AppWrapper } from 'cleveron-web-components';
import React from 'react';

import { useSelector } from '../../redux/hooks';
import ConnectedAppDrawer from '../ConnectedAppDrawer';

function ConnectedAppWrapper({ id, children }) {
    const { userExists } = useSelector(state => ({ userExists: !!state.me?.user }));

    return (
        <AppWrapper ConnectedAppDrawer={ConnectedAppDrawer} drawer={userExists} id={id}>
            {children}
        </AppWrapper>
    );
}

export default ConnectedAppWrapper;
