import { fetchStatistics, resetStatistics } from '../statisticsActions';

const resetAverageTimeInApm = resetStatistics({
    statisticsActionName: 'AVERAGE_TIME_IN_APM',
});

const fetchAverageTimeInApm = fetchStatistics({
    apiPathname: 'avgintimes',
    statisticsActionName: 'AVERAGE_TIME_IN_APM',
});

const fetchAverageTimeInApmOverClients = fetchStatistics({
    apiPathname: 'avgintimes',
    statisticsActionName: 'AVERAGE_TIME_IN_APM',
    useClientPrefix: false,
});

export { fetchAverageTimeInApm, fetchAverageTimeInApmOverClients, resetAverageTimeInApm };
