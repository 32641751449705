import axios from 'axios';

import { fetchMe } from '../../actions/me';
// eslint-disable-next-line import/no-cycle
import { axiosConfig } from '../configureStore';

let updateUserSession;

const updateSession = store => next => action => {
    const { me } = store.getState();
    const interval = 300000;
    if (interval > 0 && 'user' in me && action?.type.includes('_REQUEST')) {
        clearInterval(updateUserSession);
        updateUserSession = setInterval(() => {
            fetchMe()(store.dispatch, store.getState, { axios: axios.create(axiosConfig) });
        }, interval);
    }
    if (action.type === 'LOGOUT_REQUEST') {
        clearInterval(updateUserSession);
    }
    if (action.type === 'AUTHENTICATE_SUCCESS') {
        fetchMe()(store.dispatch, store.getState, { axios: axios.create(axiosConfig) });
    }
    return next(action);
};

export default updateSession;
