/* eslint-disable import/prefer-default-export */

import apiRequestActionCreator from './apiRequestActionCreator';

export function fetchOrderTypes() {
    return apiRequestActionCreator({
        actionName: 'ORDERTYPES',
        params: {
            url: '/stateful/v1/ordertypes',
        },
    });
}
