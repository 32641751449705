/* eslint-disable import/prefer-default-export */

import apiRequestActionCreator from './apiRequestActionCreator';

export function fetchClientSlotTypes({ clientId }) {
    return apiRequestActionCreator({
        actionName: 'CLIENT_SLOT_TYPES',
        params: {
            url: `/stateful/v1/clients/${clientId}/slottypes`,
        },
    });
}
