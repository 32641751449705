import { LoadingPage, withJob } from 'cleveron-web-components';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';

import { fetchMe as fetchMeAction } from '../../actions/me';
import { fetchPrivacyPolicy as fetchPrivacyPolicyAction } from '../../actions/privacyPolicy';
import { routeChanged as routeChangedAction } from '../../actions/route';
import { fetchVersion as fetchVersionAction } from '../../actions/version';
import Routes from '../Routes';

function ConnectedGlobalWrapper({ id, routeChanged }) {
    const { pathname } = useLocation();
    useEffect(() => {
        routeChanged(pathname);
    }, [pathname, routeChanged]);

    return <Routes id={id} />;
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            fetchMe: fetchMeAction,
            fetchPrivacyPolicy: fetchPrivacyPolicyAction,
            fetchVersion: fetchVersionAction,
            routeChanged: routeChangedAction,
        },
        dispatch
    );

export default compose(
    connect(null, mapDispatchToProps),
    withJob({
        LoadingComponent: LoadingPage,
        work: ({ fetchMe, fetchPrivacyPolicy, fetchVersion }) => {
            return Promise.all([fetchPrivacyPolicy(), fetchVersion(), fetchMe()]);
        },
    })
)(connect(null)(ConnectedGlobalWrapper));
