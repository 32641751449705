/* eslint-disable import/prefer-default-export */

import apiRequestActionCreator from './apiRequestActionCreator';

export function fetchReservedSlots({ apmId, clientId, requestActionProps }) {
    return apiRequestActionCreator({
        actionName: 'RESERVED_SLOTS',
        params: {
            url: `/stateful/v1/clients/${clientId}/apms/${apmId}/reservedslots`,
        },
        requestActionProps,
    });
}

export function saveReservedSlots({ apmId, clientId, organizedSlots, requestActionProps }) {
    return apiRequestActionCreator({
        actionName: 'SAVE_RESERVED_SLOTS',
        params: {
            data: Object.values(
                Object.values(organizedSlots).reduce((acc, { id, slotIds }) => {
                    slotIds.forEach(slot => {
                        if (!acc[slot]) {
                            acc[slot] = {
                                companies: [],
                                slotId: slot,
                            };
                        }
                        if (!acc[slot].companies.some(company => company.id === id)) {
                            acc[slot].companies.push({ id });
                        }
                    });
                    return acc;
                }, {})
            ),
            method: 'post',
            url: `/stateful/v1/clients/${clientId}/apms/${apmId}/reservedslots`,
        },
        processSuccessResponse: (response, params, dispatch) =>
            dispatch(
                fetchReservedSlots({
                    apmId,
                    clientId,
                    requestActionProps,
                })
            ),
    });
}
