import { fetchStatistics, resetStatistics } from '../statisticsActions';

const resetAverageParcelHeights = resetStatistics({
    statisticsActionName: 'AVERAGE_PARCEL_HEIGHTS',
});

const fetchAverageParcelHeights = fetchStatistics({
    apiPathname: 'avgheights',
    statisticsActionName: 'AVERAGE_PARCEL_HEIGHTS',
});

const fetchAverageParcelHeightsOverClients = fetchStatistics({
    apiPathname: 'avgheights',
    statisticsActionName: 'AVERAGE_PARCEL_HEIGHTS',
    useClientPrefix: false,
});

export {
    fetchAverageParcelHeights,
    fetchAverageParcelHeightsOverClients,
    resetAverageParcelHeights,
};
