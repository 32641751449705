/* eslint-disable import/prefer-default-export */

import { PERMISSION_TYPES } from '../../constants';
import { addItem, fetchCollection, fetchItem, updateItem } from '../collectionActions';

const fetchOfficeRoles = fetchCollection({
    apiCollectionName: 'roles',
    apiVersion: 'v2',
    collectionActionName: 'OFFICE_ROLES',
    paramsFunction: ({ params }) => ({
        ...params,
        typeId: PERMISSION_TYPES.OFFICE,
    }),
});

const addOfficeRole = addItem({
    apiCollectionName: 'roles',
    itemActionName: 'OFFICE_ROLE',
});

const fetchOfficeRole = fetchItem({
    apiCollectionName: 'roles',
    itemActionName: 'OFFICE_ROLE',
});

const updateOfficeRole = updateItem({
    apiCollectionName: 'roles',
    itemActionName: 'OFFICE_ROLE',
});

export { addOfficeRole, fetchOfficeRole, fetchOfficeRoles, updateOfficeRole };
