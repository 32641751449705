/* eslint-disable import/prefer-default-export */

import { addItem, fetchCollection, fetchItem, updateItem } from '../collectionActions';

const fetchApmLanguagePacks = fetchCollection({
    apiCollectionName: 'uilanguagepacks',
    collectionActionName: 'APM_LANGUAGE_PACKS',
});

const addApmLanguagePack = addItem({
    apiCollectionName: 'uilanguagepacks',
    itemActionName: 'APM_LANGUAGE_PACK',
});

const fetchApmLanguagePack = fetchItem({
    apiCollectionName: 'uilanguagepacks',
    itemActionName: 'APM_LANGUAGE_PACK',
});

const updateApmLanguagePack = updateItem({
    apiCollectionName: 'uilanguagepacks',
    itemActionName: 'APM_LANGUAGE_PACK',
});

export { addApmLanguagePack, fetchApmLanguagePack, fetchApmLanguagePacks, updateApmLanguagePack };
