/* eslint-disable import/prefer-default-export */

import { addItem, fetchCollection, fetchItem, updateItem } from '../collectionActions';

const paramsFunction = ({ params }) => ({
    ...params,
    apmGroups: params.apmGroups?.map(id => ({ apmGroupId: id })) || null,
    apmRoles: params.apmRoles?.map(id => ({ id })) || null,
    headers: params.headers
        ? params.headers.reduce((acc, curr) => (curr.name ? [...acc, curr] : acc), [])
        : [],
    officeRoles: params.officeRoles?.map(id => ({ id })) || null,
    patterns: params.patterns
        ? params.patterns.reduce(
              (acc, curr) => (curr.type && curr.expression ? [...acc, curr] : acc),
              []
          )
        : [],
});

const fetchCompanies = fetchCollection({
    apiCollectionName: 'logisticscompanies',
    apiVersion: 'v2',
    collectionActionName: 'COMPANIES',
});

const fetchCompaniesOverClients = fetchCollection({
    apiCollectionName: 'companies',
    collectionActionName: 'COMPANIES',
    useClientPrefix: false,
});

const addCompany = addItem({
    apiCollectionName: 'logisticscompanies',
    itemActionName: 'COMPANY',
    paramsFunction,
});

const fetchCompany = fetchItem({
    apiCollectionName: 'logisticscompanies',
    itemActionName: 'COMPANY',
});

const updateCompany = updateItem({
    apiCollectionName: 'logisticscompanies',
    itemActionName: 'COMPANY',
    paramsFunction,
});

export { addCompany, fetchCompanies, fetchCompaniesOverClients, fetchCompany, updateCompany };
