/* eslint-disable import/prefer-default-export */

import { addItem, fetchCollection, fetchItem, updateItem } from '../collectionActions';

const fetchApmLocations = fetchCollection({
    apiCollectionName: 'apmlocations',
    collectionActionName: 'APM_LOCATIONS',
});

const fetchApmLocationsOverClients = fetchCollection({
    apiCollectionName: 'apmlocations',
    collectionActionName: 'APM_LOCATIONS',
    useClientPrefix: false,
});

const addApmLocation = addItem({
    apiCollectionName: 'apmlocations',
    itemActionName: 'APM_LOCATION',
});

const fetchApmLocation = fetchItem({
    apiCollectionName: 'apmlocations',
    itemActionName: 'APM_LOCATION',
});

const updateApmLocation = updateItem({
    apiCollectionName: 'apmlocations',
    itemActionName: 'APM_LOCATION',
});

export {
    addApmLocation,
    fetchApmLocation,
    fetchApmLocations,
    fetchApmLocationsOverClients,
    updateApmLocation,
};
