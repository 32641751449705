/* eslint-disable import/prefer-default-export */

import apiRequestActionCreator from './apiRequestActionCreator';

function statisticsReset({ statisticsActionName }) {
    return { type: `${statisticsActionName}_RESET` };
}

export const resetStatistics =
    ({ statisticsActionName }) =>
    () =>
    dispatch =>
        dispatch(statisticsReset({ statisticsActionName }));

export function fetchStatistics({ apiPathname, statisticsActionName, useClientPrefix = true }) {
    return ({ clientId, timePeriod, endTime, startTime, ...params }) =>
        apiRequestActionCreator({
            actionName: statisticsActionName,
            params: {
                params: {
                    ...params,
                    endTime: endTime.slice(0, -1),
                    startTime: startTime.slice(0, -1),
                },
                url: `/stateful/v1/${
                    useClientPrefix ? `clients/${clientId}/` : ''
                }statistics/${apiPathname}`,
            },
            processSuccessResponse: ({ data }) => ({
                ...data,
                queryParams: { ...params, endTime, startTime, timePeriod },
            }),
        });
}
