/* eslint-disable import/prefer-default-export */

import apiRequestActionCreator from './apiRequestActionCreator';

export function fetchMultiOrderCodePatternSettings() {
    return apiRequestActionCreator({
        actionName: 'MULTI_ORDER_CODE_PATTERN_SETTINGS',
        params: {
            url: `/stateful/v1/multiordercodepatternsettings`,
        },
    });
}
