/* eslint-disable import/prefer-default-export */
import apiRequestActionCreator from './apiRequestActionCreator';

export function fetchClientPasswordPolicy({ clientId }) {
    return apiRequestActionCreator({
        actionName: 'PASSWORD_POLICY',
        params: {
            url: `/stateful/v1/clients/${clientId}/passwordpolicy`,
        },
    });
}
