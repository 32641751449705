/* eslint-disable import/prefer-default-export */

import { hasValue } from 'cleveron-web-components/utils/validators';

import { addItem, fetchCollection, fetchItem, updateItem } from '../collectionActions';

const paramsFunction = ({
    params: {
        assignedToCompanyList,
        clientDefaultTemplate,
        companyDefaultTemplate,
        selectedCompanies,
        ...other
    },
}) => ({
    ...other,
    assignedToCompanyList: selectedCompanies?.map(id => {
        const assignedCompany = assignedToCompanyList?.find(({ id: cid }) => cid === id);
        return {
            defaultTemplate: hasValue(companyDefaultTemplate)
                ? companyDefaultTemplate
                : assignedCompany?.defaultTemplate || false,
            id,
        };
    }),
    defaultTemplate: clientDefaultTemplate,
});

const fetchTemplates = fetchCollection({
    apiCollectionName: 'templates',
    collectionActionName: 'TEMPLATES',
});

const addTemplate = addItem({
    apiCollectionName: 'templates',
    itemActionName: 'TEMPLATE',
    paramsFunction,
});

const fetchTemplate = fetchItem({
    apiCollectionName: 'templates',
    itemActionName: 'TEMPLATE',
});

const updateTemplate = updateItem({
    apiCollectionName: 'templates',
    itemActionName: 'TEMPLATE',
    paramsFunction,
});

export { addTemplate, fetchTemplate, fetchTemplates, updateTemplate };
