/* eslint-disable import/prefer-default-export */

import apiRequestActionCreator from './apiRequestActionCreator';

export function fetchApmTypes() {
    return apiRequestActionCreator({
        actionName: 'APM_TYPES',
        params: {
            url: `/stateful/v1/apmtypes`,
        },
    });
}
