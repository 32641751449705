/* eslint-disable import/prefer-default-export */

import apiRequestActionCreator from './apiRequestActionCreator';

export function fetchClientApiKeys({ clientId }) {
    return apiRequestActionCreator({
        actionName: 'CLIENT_API_KEYS',
        params: {
            url: `/stateful/v1/clients/${clientId}/apikeys`,
        },
    });
}

export function generateClientApiKey({ clientId, type }) {
    return apiRequestActionCreator({
        actionName: 'GENERATE_CLIENT_API_KEY',
        params: {
            data: { type },
            method: 'post',
            url: `/stateful/v1/clients/${clientId}/apikeys`,
        },
    });
}

export function deleteClientApiKey({ clientId, id }) {
    return apiRequestActionCreator({
        actionName: 'DELETE_CLIENT_API_KEY',
        actionParams: { id },
        params: {
            method: 'delete',
            url: `/stateful/v1/clients/${clientId}/apikeys/${id}`,
        },
    });
}
