import { Container } from '@mui/material';
import { Page } from 'cleveron-web-components';
import React, { useState } from 'react';
import { defineMessage } from 'react-intl';

import { USER_ACTION_TOKEN_TYPES } from '../../../../constants';
import { useActions, useSelector } from '../../../../redux/hooks';
import AuthFormHeader from '../../../ConnectedAuthWrapper/AuthFormHeader';
import AuthPageTitle from '../../../ConnectedAuthWrapper/AuthPageTitle';
import UserActionTokenForm from '../UserActionTokenForm';

function PasswordResetToken({ id }) {
    const { locale, userActionToken } = useSelector(state => ({
        locale: state.locale,
        userActionToken: state.userActionToken,
    }));
    const { requestUserActionToken } = useActions();

    const [submitSucceeded, setSubmitSucceeded] = useState(false);

    return (
        <Page
            id={id}
            titleKey={
                defineMessage({ defaultMessage: 'Reset password', id: 'page.passwordReset' }).id
            }
        >
            <Container maxWidth="sm">
                {submitSucceeded ? (
                    <AuthPageTitle
                        id={`${id}TitleSuccess`}
                        titleKey={
                            defineMessage({
                                defaultMessage: 'Confirmation link was sent to your e-mail!',
                                id: 'passwordReset.text.tokenEmailSent',
                            }).id
                        }
                    />
                ) : (
                    <UserActionTokenForm
                        FormHeader={
                            <AuthFormHeader
                                id={`${id}TokenFormHeader`}
                                initialSubmitError={
                                    userActionToken?.error &&
                                    defineMessage({
                                        defaultMessage:
                                            'Password reset link has expired. To request a new link, please enter your email.',
                                        id: 'passwordReset.error.tokenExpired',
                                    })
                                }
                                subtitleKey={
                                    defineMessage({
                                        defaultMessage:
                                            'Please enter the email address you used when creating your account',
                                        id: 'passwordReset.text.enterEmailAddress',
                                    }).id
                                }
                                titleKey={
                                    defineMessage({
                                        defaultMessage: 'Forgot password',
                                        id: 'passwordReset.text.forgot',
                                    }).id
                                }
                            />
                        }
                        id={`${id}TokenForm`}
                        onSubmit={props =>
                            requestUserActionToken({
                                ...props,
                                locale,
                                type: USER_ACTION_TOKEN_TYPES.PASSWORD_RESET,
                            })
                        }
                        onSubmitSuccess={() => setSubmitSucceeded(true)}
                    />
                )}
            </Container>
        </Page>
    );
}

export default PasswordResetToken;
