/* eslint-disable import/prefer-default-export */

import { addItem, fetchCollection, fetchItem, updateItem } from '../collectionActions';

const fetchApmLanguages = fetchCollection({
    apiCollectionName: 'uilanguages',
    collectionActionName: 'APM_LANGUAGES',
});

const paramsFunction = ({
    params: {
        areKeysVisible,
        element,
        languagePacks,
        search,
        session,
        status,
        translations,
        ...params
    },
}) => ({
    ...params,
    translations: Object.entries(translations || {}).reduce(
        (acc, [key, value]) => ({
            ...acc,
            [key.replaceAll('%%', '.')]: value,
        }),
        {}
    ),
});

const addApmLanguage = addItem({
    apiCollectionName: 'uilanguages',
    itemActionName: 'APM_LANGUAGE',
    paramsFunction,
});

const fetchApmLanguage = fetchItem({
    apiCollectionName: 'uilanguages',
    itemActionName: 'APM_LANGUAGE',
});

const updateApmLanguage = updateItem({
    apiCollectionName: 'uilanguages',
    itemActionName: 'APM_LANGUAGE',
    paramsFunction,
});

function translationsImportFailure(payload) {
    return { payload, type: 'TRANSLATIONS_IMPORT_FAILURE' };
}

function translationsImportSuccess(payload) {
    return { payload, type: 'TRANSLATIONS_IMPORT_SUCCESS' };
}

export {
    addApmLanguage,
    fetchApmLanguage,
    fetchApmLanguages,
    updateApmLanguage,
    translationsImportFailure,
    translationsImportSuccess,
};
